import React from "react";
import "./Container.scss";

const Container = ({ children }: any) => {
  return (
    <div className="parent">
      <div className="container">{children}</div>
    </div>
  );
};

export default Container;
