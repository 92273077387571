import React, { Component, useEffect } from 'react';
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import Header from '../../components/header/Header';
import './Events.css';
import AuthGuard from '../../services/UserAuth.service';
import { observer, inject } from 'mobx-react';
import EventStore from '../../mobx/EventStore';
import EventCard from './Events-Card/EventCard';

interface EventsProps {
  eventStore?: EventStore;
}

const Events = ({ eventStore }: EventsProps) => {
  useEffect(() => {
    eventStore!.loadEvents();
    // eventStore.loadFeaturedEvent();
    return () => {};
  }, []);

  const generateEvents = () => {
    return eventStore!.events.map((event, key) => {
      return (
        <EventCard
          item={event}
          key={key}
          imageFullscreen
          style={{ margin: 0, padding: 0, marginBottom: 15 }}
        />
      );
    });
  };

  return (
    <div style={{ width: '100%', backgroundColor: '#007234' }}>
      <div className='events-overview-container'>
        <TitleHeaderGreen title='Events' subtitle='Wir freuen uns auf dich!' />
        {eventStore!.featuredEvent && (
          <div
            className='shop-divider'
            style={{ marginTop: 23, marginBottom: 12 }}
          ></div>
        )}
        {eventStore!.events.length > 0 && eventStore!.loaded && (
          <div className='events-container'>{generateEvents()}</div>
        )}
        {eventStore!.events.length == 0 && eventStore!.loaded && (
          <div className='events-no-events'>
            Derzeit sind keine Events geplant.
          </div>
        )}
      </div>
      <Header />
    </div>
  );
};

export default inject('eventStore')(observer(Events));
