import React, { Component, useState } from "react";
import "./EmailRegistration.css";

import TextInput from "../../components/TextInput/TextInput";
import Loading from "../../components/loading/Loading";
import Header from "../../components/header/Header";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import UserStore from "../../mobx/UserStore";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import YellowButton from "../../components/Yellow-Button/Yellow-Button";

interface EmailForgotPasswordProps {
  userStore?: UserStore;
}

const EmailForgotPassword = ({ userStore }: EmailForgotPasswordProps) => {
  const [email, setEmail] = useState("");

  const history = useHistory();

  return (
    <div className="register-container">
      <div className="register-content">
        {userStore!.loading ? (
          <Loading isVisible={userStore!.loading} />
        ) : (
          <div className="register-container-form">
            <div className="headertitle">Passwort vergessen</div>
            <TextInput
              placeholder="E-Mail"
              type="email"
              value={email}
              onChange={(event: any) => {
                setEmail(event.target.value);
              }}
            ></TextInput>
            <YellowButton
              style={{ marginTop: "10px" }}
              label="Passwort zurücksetzen"
              onClick={() => {
                if (email === "") {
                  Swal.fire({
                    text: "Bitte gib deine Email-Adresse ein!",
                    type: "error",
                    confirmButtonColor: "#ffd42e",
                    confirmButtonText: "Ok",
                  });
                  return;
                }
                userStore!
                  .forgotPassword(email)
                  .then((resp) => {
                    userStore!.stopLogin();
                    Swal.fire({
                      text:
                        "Wir haben dir soeben ein E-Mail mit weiteren Anweisungen zum Zurücksetzen des Passworts geschickt.",
                      type: "success",
                      confirmButtonColor: "#ffd42e",
                      confirmButtonText: "Ok",
                    });
                    history.push("/emaillogin");
                  })
                  .catch((err) => {
                    userStore!.stopLogin();
                    Swal.fire({
                      text: "Es ist ein Fehler aufgetreten!",
                      type: "error",
                      confirmButtonColor: "#ffd42e",
                      confirmButtonText: "Ok",
                    });
                  });
              }}
            />
          </div>
        )}
      </div>
      <Header backEnabled />
    </div>
  );
};

export default inject("userStore")(observer(EmailForgotPassword));