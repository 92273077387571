import React, { useState } from "react";
import Skeleton from "react-skeleton-loader";

interface ImageWithSkeletonProps {
  image: string;
  width: string;
  height: string;
  borderRadius: string;
  imageClassName?: any;
  onImageClick?: () => void;
  style?: any;
}

const ImageWithSkeleton = ({
  image,
  width,
  height,
  borderRadius,
  imageClassName,
  onImageClick,
  style,
}: ImageWithSkeletonProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div  style={{ backgroundColor: 'rgb(0, 114, 52)'}}>
      <div style={{ display: isLoaded ? "block" : "none", backgroundColor: 'rgb(0, 114, 52)' }}>
        <img
          src={image}
          style={{ backgroundColor: 'rgb(0, 114, 52)'}}
          className={imageClassName}
          width={width}
          height={height}
          onClick={onImageClick}
          onLoad={() => setIsLoaded(true)}
        />
      </div>


      <div style={{ display: isLoaded ? "none" : "block" }}>
        <Skeleton
          width={width}
          height={height}
          widthRandomness={0}
          animated={false}
          borderRadius={borderRadius}
        />
      </div>
    </div>
  );
};

export default ImageWithSkeleton;

// interface ImageWithSkeletonState {
//   isLoaded: boolean;
// }

// /***
//  * This class displays a Skeleton (grayed area) while the image is loading. After loading, it will display the image.
//  */
// export default class ImageWithSkeleton extends React.Component<
//   ImageWithSkeletonProps,
//   ImageWithSkeletonState
// > {
//   state: ImageWithSkeletonState = {
//     isLoaded: false,
//   };

//   render() {
//     return (
//       <div>
//         <div style={{ display: isLoaded ? "block" : "none" }}>
//           <img
//             src={image}
//             className={imageClassName}
//             width={width}
//             height={height}
//             onClick={onImageClick}
//             onLoad={() => this.setState({ isLoaded: true })}
//           />
//         </div>

//         <div style={{ display: isLoaded ? "none" : "block" }}>
//           <Skeleton
//             width={width}
//             height={height}
//             widthRandomness={0}
//             animated={false}
//             borderRadius={borderRadius}
//           />
//         </div>
//       </div>
//     );
//   }
// }
