import React, { Component } from 'react';
import './AccountSetup.css';
import plus from '../../../assets/plus-green.png';

import { getAddressFromPlace } from '../../../services/GetAddressFromGooglePlace.service';
import defaultPLZ from '../../../assets/PLZ';

import placeholder from '../../../assets/imageplaceholder.png';
import TextInput from '../../../components/TextInput/TextInput';
import Loading from '../../../components/loading/Loading';

const customStyles = {
  content: {
    backgroundColor: '#007234',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    padding: 0,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
  },
};

interface AccountSetupProps {
  error: any;
  uploadImage: any;
  checkAuth: any;
  updateUser: any;
  currentUser: any;
  goBack: any;
  location: any;
}

interface AccountSetupsState {
  img: string;
  fname: string;
  lname: string;
  location: string;
  street: string;
  plz: string;
  country: string;
}

class AccountSetup extends Component<AccountSetupProps, AccountSetupsState> {
  private inputRef;

  constructor(props: AccountSetupProps) {
    super(props);
    this.state = {
      fname: props.currentUser.fname ? props.currentUser.fname : '',
      lname: props.currentUser.lname ? props.currentUser.lname : '',
      img: '',
      street: '',
      plz: '',
      location: '',
      country: 'Österreich',
    };
  }

  componentWillMount() {
    this.props.checkAuth();
  }
  componentDidMount() {}
  checkEmail() {
    if (
      (this.props.currentUser.email &&
        this.props.currentUser.email.length <= 0) ||
      !this.validateEmail(this.props.currentUser.email)
    ) {
      return false;
    }

    return true;
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getCity(value) {
    return defaultPLZ.filter(function (data) {
      return data.value == value;
    });
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  }

  canUpdateUser() {
    if (!this.checkEmail()) {
      return false;
    }

    if (this.state.fname.length === 0 || this.state.fname.length > 15) {
      return false;
    }

    if (this.state.lname.length === 0 || this.state.lname.length > 15) {
      return false;
    }

    if (!this.state.location && this.props.currentUser.first_login) {
      return false;
    }

    if (this.state.street.length === 0) {
      return false;
    }
    if (this.state.plz.length === 0) {
      return false;
    }
    if (this.state.location.length === 0) {
      return false;
    }
    if (this.state.country.length === 0) {
      return false;
    }

    return true;
  }

  updateUser() {
    if (!this.canUpdateUser()) {
      return;
    }

    this.props.updateUser(
      this.props.currentUser,
      this.state.fname,
      this.state.lname,
      this.state.street,
      this.state.plz,
      this.state.location,
      this.state.country
    );
  }
  onPlaceSelected = (place) => {
    const address = getAddressFromPlace(place);
    this.setState({
      street: address.street + (address.streetNr ? ' ' + address.streetNr : ''),
      location: address.city,
      plz: address.zip,
      country: address.country,
    });
  };

  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='personal-space' style={{ backgroundColor: '#007234' }}>
          <div className='profile-title'>Profil vervollständigen</div>
          <div className='personal-space-image-container'>
            <div style={{ position: 'relative' }}>
              <img
                src={
                  this.props.currentUser.image
                    ? this.props.currentUser.image
                    : placeholder
                }
                className='personal-space-image'
                style={{ borderRadius: 100 }}
              />
              <div className='upload-container'>
                <img
                  src={plus}
                  className='personal-space-image-btn'
                  onClick={() => {}}
                />
                <input
                  type='file'
                  name='pic'
                  accept='image/png,image/x-png,image/jpeg,image/jpg'
                  className='personal-space-input'
                  style={{
                    position: 'absolute',
                    width: 29,
                    backgroundColor: 'rgba(0,0,0,0)',
                  }}
                  onChange={(result: any) => {
                    this.getBase64(result.target.files[0], (result) => {
                      this.props.uploadImage(result);
                    });
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div className='personal-space-input-container'>
            <div style={{ display: 'flex' }}>
              <TextInput
                placeholder='Vorname'
                value={this.state.fname}
                onChange={(event: any) => {
                  this.setState({
                    fname: event.target.value,
                  });
                }}
              ></TextInput>
              <div style={{ width: 10 }}></div>
              <TextInput
                placeholder='Nachname'
                value={this.state.lname}
                onChange={(event: any) => {
                  this.setState({
                    lname: event.target.value,
                  });
                }}
              ></TextInput>
            </div>
            <TextInput
              placeholder='E-Mail-Adresse'
              value={
                this.props.currentUser.email ? this.props.currentUser.email : ''
              }
              onChange={(event: any) => {}}
              disabled={true}
            ></TextInput>

            <TextInput
              placeholder='Straße und Hausnummer'
              value={this.state.street}
              isGoogleInput={true}
              onGooglePlaceSelected={(place: any) =>
                this.onPlaceSelected(place)
              }
              onChange={(event: any) => {
                this.setState({
                  street: event.target.value,
                });
              }}
            ></TextInput>

            <div className='shipment-address-split-text-input'>
              <TextInput
                placeholder='PLZ'
                value={this.state.plz}
                type='number'
                style={{ marginRight: 5 }}
                onChange={(event: any) => {
                  this.setState({
                    plz: event.target.value,
                  });
                }}
              ></TextInput>
              <TextInput
                placeholder='Stadt'
                value={this.state.location}
                style={{ marginLeft: 5 }}
                onChange={(event: any) => {
                  this.setState({
                    location: event.target.value,
                  });
                }}
              ></TextInput>
            </div>
            <TextInput
              placeholder='Land'
              value={this.state.country}
              style={{ marginRight: 5 }}
              disabled={true}
              onChange={(event: any) => {
                this.setState({
                  country: event.target.value,
                });
              }}
            ></TextInput>

            <div
              className={
                'personal-space-btn-edit ' +
                (!this.canUpdateUser() && 'personal-space-btn-edit-disabled')
              }
              onClick={() => this.updateUser()}
            >
              <div className='personal-space-btn-edit-text'>
                Jetzt geht´s los
              </div>
            </div>
            <div style={{ height: 50 }}></div>
          </div>
        </div>
        <Loading />
      </div>
    );
  }
}

// const mapStateToProps = (state: any) => {
//   return {
//     currentUser: state.user.user,
//     email: state.user.user.email,
//     plz: state.user.user.PLZ,
//     error: state.user.error,
//   };
// };

// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     updateUser(
//       user: any,
//       fname: string,
//       lname: string,
//       street: string,
//       plz: string,
//       location: string,
//       country: string
//     ) {
//       user = Object.assign({}, user, {
//         fname: fname,
//         lname: lname,
//         street,
//         plz,
//         location,
//         country,
//       });
//       const firstLogin = user.first_login;
//       if (user.first_login) {
//         user.first_login = false;
//       }
//       if (firstLogin) {
//         dispatch(updateUser(user, "/home"));
//       } else {
//         dispatch(updateUser(user, "/home"));
//       }
//     },
//     checkAuth() {
//       AuthGuard(dispatch);
//     },
//     uploadImage(image: string) {
//       dispatch(uploadImageOnly(image));
//     },
//     goBack() {
//       dispatch(goBack());
//     },
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(AccountSetup);

export default AccountSetup;
