import React, { Component } from "react";
import "./White-Button.css";

class WhiteButton extends Component<
  {
    label: string;
    action?: any;
    uppercase?: boolean;
    disabled?: boolean;
    style?: any;
  },
  {}
> {
  componentDidMount() {}
  render() {
    if (this.props.disabled) {
      return (
        <button
          className="disable-white-button"
          style={this.props.style}
          onClick={() => {
            this.props.action();
          }}
        >
          {this.props.label}
        </button>
      );
    } else if (this.props.action) {
      return (
        <button
          className="white-button"
          style={this.props.style}
          onClick={() => {
            this.props.action();
          }}
        >
          {this.props.label}
        </button>
      );
    } else {
      return (
        <button className="white-button" style={this.props.style}>
          {this.props.label}
        </button>
      );
    }
  }
}

export default WhiteButton;