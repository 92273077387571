import React, { Component, useEffect } from "react";
import Header from "../../components/header/Header";
import AuthGuard from "../../services/UserAuth.service";
import Moment from "react-moment";
import "moment/locale/de";
import "./NewsDetails.css";
import infoImg from "../../assets/events/info.png";
import ImageWithSkeleton from "../../components/ImageWithSkeleton/ImageWithSkeleton";
import Skeleton from "react-skeleton-loader";
import { observer, inject } from "mobx-react";
import NewsStore from "../../mobx/NewsStore";
import { useHistory } from "react-router-dom";

interface NewsDetailsProps {
  match: any;
  newsStore?: NewsStore;
}

const NewsDetails = ({ match, newsStore }: NewsDetailsProps) => {
  const history = useHistory();
  const { newsId } = match.params;

  useEffect(() => {
    if (match.params.newsId) {
      newsStore!.loadNewsArticle(newsId);
    } else {
      history.push("/home");
    }
    window.scroll(0, 0);
    return () => {};
  }, []);

  function renderNewsDetails() {
    let date = newsStore!.currentNews!.updated_at;
    return (
      <div className="news-details-container-wrapper">
        <div className="news-details-info-container">
          <ImageWithSkeleton
            image={infoImg}
            imageClassName="news-details-info-image"
            width="18px"
            height="18px"
            borderRadius="9px"
          />
          <div className="shop-detail-text">
            <Moment date={date} format={"DD.MM.YYYY"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", paddingTop: 70 }}>
      {newsStore!.currentNews ? (
        <div className="news-details-container">
          {newsStore!.currentNews.image && (
            <ImageWithSkeleton
              image={newsStore!.currentNews.image.url}
              imageClassName="news-details-image"
              width="100%"
              height="338px"
              borderRadius="0px"
            />
          )}
          <div className="news-details-container-content">
            <div className="news-details-container-title">
              {newsStore!.currentNews.name}
            </div>
            {renderNewsDetails()}
            <div className="news-details-container-description">
              {newsStore!.currentNews.description &&
                newsStore!.currentNews.description
                  .split("\n")
                  .map((item, key) => {
                    return (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    );
                  })}
            </div>
          </div>
        </div>
      ) : (
        <div className="news-details-container">
          <ImageWithSkeleton
            image=""
            imageClassName="news-details-image"
            width="100%"
            height="338px"
            borderRadius="0px"
          />
          <div className="news-details-container-content">
            <div className="news-details-container-title">
              <Skeleton />
            </div>
            <div className="news-details-container-description">
              <Skeleton count={7} />
            </div>
          </div>
        </div>
      )}
      <Header backEnabled backUrl={"/home"} />
    </div>
  );
};

export default inject("newsStore")(observer(NewsDetails));
