import React, { Component, useEffect, useState } from "react";
import "./OrderReview.css";
import { inject, observer } from "mobx-react";
import ShopStore from "../../../mobx/ShopStore";

import Header from "../../../components/header/Header";
import YellowButton from "../../../components/Yellow-Button/Yellow-Button";
import { TitleHeaderGreen } from "../../../components/title-header-green/TitleHeaderGreen";
import TextInput from "../../../components/TextInput/TextInput";
import OrderPart from "../../../models/OrderPart";
import ShoppingCartItem from "../ShoppingCart/ShoppingCartItem/ShoppingCartItem";
import Overlay from "../../../components/Overlay/Overlay";
import CardSection from "./CardSection";
import InjectedCheckoutForm from "./CheckoutForm";
import Loading from "../../../components/loading/Loading";
import OutOfStock from "../OutOfStock/OutOfStock";
import Product from "../../../models/Product";
import { useHistory } from "react-router-dom";
import WhiteButton from "../../../components/White-Button/White-Button";

interface OrderReviewProps {
  shopStore?: ShopStore;
}

const OrderReview = ({ shopStore }: OrderReviewProps) => {
  const history = useHistory();
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [price, setPrice]: any = useState(0);
  const [deliverCost, setDeliverCost] = useState(0);
  const [outOfStockProduct, setOutOfStockProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!shopStore!.currentOrder) {
      history.push("/home");
    }
    shopStore!.setOrderNumber("");
  }, []);

  useEffect(() => {
    const loadShipmentCosts = async () => {
      const cost = await shopStore!.getShipmentCosts();
      let price = calculatePrice();
      setPrice(price);
      setDeliverCost(cost.totalPrice);
    };
    if (shopStore!.shoppingCart) {
      loadShipmentCosts();
    }
  }, [shopStore!.shoppingCart]);

  function _renderShopItems() {
    return shopStore!.shoppingCart.map((item: OrderPart, key) => {
      return (
        <ShoppingCartItem
          //   shopStore={shopStore}
          orderPart={item}
          key={key}
          hideDelete
        />
      );
    });
  }

  const calculatePrice = () => {
    let calculation = 0;
    for (let item of shopStore!.shoppingCart) {
      let amount = 0;
      if (item.amount) {
        amount = item.amount;
      }
      calculation += item.product.price * amount;
    }
    return calculation;
  };

  const generateSum = (currentOrder) => {
    if (!currentOrder) {
      return "";
    }
    if (currentOrder && currentOrder.isPickup) {
      return price.toFixed(2).replace(".", ",") + "€";
    }
    return (
      ((!currentOrder.isPickup ? deliverCost : 0) + price)
        .toFixed(2)
        .replace(".", ",") + "€"
    );
  };

  function _renderSum() {
    const currentOrder = shopStore!.currentOrder;

    return (
      <div>
        {currentOrder && !currentOrder.isPickup && (
          <div className="shopping-card-calculation">
            <div className="shopping-card-calculation-text">Lieferkosten</div>
            <div className="shopping-card-calculation-text">
              {deliverCost
                ? ("" + deliverCost.toFixed(2)).replace(".", ",") + "€"
                : ""}
            </div>
          </div>
        )}
        <div className="shopping-card-seperator"></div>
        <div className="shopping-card-calculation">
          <div className="shopping-card-calculation-text">
            Summe ({shopStore!.getNumberOfCartItems()} Artikel)
          </div>
          <div className="shopping-card-calculation-text">
            {generateSum(currentOrder)}
          </div>
        </div>
      </div>
    );
  }

  function _renderContent() {
    return (
      <div className="order-review">
        <TitleHeaderGreen title="Bestellung" subtitle="" />
        <div style={{ maxHeight: "70%", overflow: "auto", flexGrow: 1 }}>
          {_renderShopItems()}
        </div>
      </div>
    );
  }

  const renderPaymentModal = () => {
    return <Overlay isOpen={true} component={<InjectedCheckoutForm />} />;
  };

  const _onBuyNow = async () => {
    setIsLoading(true);
    if (shopStore!.currentOrder && !shopStore!.orderNumber) {
      const result: any = await shopStore!.createIntend(
        shopStore!.currentOrder
      );
      if (result && result.response && result.response.status === 303) {
        setIsOutOfStock(true);
        setOutOfStockProduct(result.response.data.message);
      }
      setIsLoading(false);
    } else {
      shopStore!.displayPaymentDialog();
      setIsLoading(false);
    }
  };

  if (shopStore!.loading) {
    return <Loading show={true} />;
  }

  return (
    <div className="order-review-fullheight">
      {shopStore!.showPaymentDialog && renderPaymentModal()}
      {_renderContent()}
      <div className="m-20">
        {_renderSum()}
        <WhiteButton label="Jetzt kaufen" action={_onBuyNow} />
      </div>
      <Header
        backEnabled
        customBack={() => {
          shopStore!.hidePaymentDialog();
          shopStore!.setOrderNumber("");
          history.goBack();
        }}
      />
    </div>
  );
};

export default inject("shopStore")(observer(OrderReview));

// interface OrderReviewProps {
//   navigateTo: Function;
//   shopStore: ShopStore;
//   goBack: Function;
// }

// interface OrderReviewState {
//   isOutOfStock: boolean;
//   outOfStockProduct: Product | null;
// }

// @inject("shopStore")
// @observer
// class OrderReview extends Component<OrderReviewProps, OrderReviewState> {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOutOfStock: false,
//       outOfStockProduct: null,
//     };
//   }

//   componentDidMount() {
//     if (!shopStore!.currentOrder) {
//       navigateTo("/home");
//     }
//     shopStore!.setOrderNumber("");
//   }

//   _calculatePrice() {
//     let calculation = 0;
//     let deliveryCost = 0;
//     for (let item of shopStore!.shoppingCart) {
//       let amount = 0;
//       if (item.amount) {
//         amount = item.amount;
//       }
//       deliveryCost +=
//         (item.product.deliverycost ? item.product.deliverycost : 1) * amount;
//       calculation += item.product.price * amount;
//     }
//     return { calculation, deliveryCost };
//   }

//   _renderShopItems() {
//     return shopStore!.shoppingCart.map((item: OrderPart, key) => {
//       return (
//         <ShoppingCartItem
//           //   shopStore={shopStore}
//           orderPart={item}
//           key={key}
//           hideDelete
//         />
//       );
//     });
//   }

//   _renderSum() {
//     const currentOrder = shopStore!.currentOrder;
//     var delieveryCosts = (currentOrder && currentOrder.deliveryCosts) || 0.0;
//     return (
//       <div>
//         {currentOrder && !currentOrder.isPickup && (
//           <div className="shopping-card-calculation">
//             <div className="shopping-card-calculation-text">Lieferkosten</div>
//             <div className="shopping-card-calculation-text">
//               {("" + this._calculatePrice().deliveryCost.toFixed(2)).replace(
//                 ".",
//                 ","
//               )}{" "}
//               €
//             </div>
//           </div>
//         )}
//         <div className="shopping-card-seperator"></div>
//         <div className="shopping-card-calculation">
//           <div className="shopping-card-calculation-text">
//             Summe ({shopStore!.getNumberOfCartItems()} Artikel)
//           </div>
//           <div className="shopping-card-calculation-text">
//             {(
//               "" +
//               (
//                 this._calculatePrice().calculation +
//                 (currentOrder && !currentOrder.isPickup
//                   ? this._calculatePrice().deliveryCost
//                   : 0)
//               ).toFixed(2)
//             ).replace(".", ",")}{" "}
//             €
//           </div>
//         </div>
//       </div>
//     );
//   }

//   _renderContent() {
//     return (
//       <div className="order-review">
//         <TitleHeaderGreen title="Bestellung" subtitle="" />
//         <div style={{ maxHeight: "70%", overflow: "scroll", flexGrow: 1 }}>
//           {this._renderShopItems()}
//         </div>
//       </div>
//     );
//   }

//   // _renderPaymentModal() {
//   //     return (
//   //         <Overlay isOpen={true} component={<InjectedCheckoutForm shopStore={shopStore} />} />
//   //     )
//   // }

//   // _renderOutOfStockModal = () => {
//   //     return (
//   //         <Overlay isOpen={true} component={
//   //             <OutOfStock
//   //                 //@ts-ignore
//   //                 product={this.state.outOfStockProduct}
//   //                 onContinue={() =>
//   //                     this.setState({
//   //                         isOutOfStock: false,
//   //                         outOfStockProduct: null
//   //                     })
//   //                 }
//   //             />
//   //         } />
//   //     )
//   // }

//   _onBuyNow = async () => {
//     if (
//       shopStore!.currentOrder &&
//       !shopStore!.orderNumber
//     ) {
//       const result = await shopStore!.createIntend(
//         shopStore!.currentOrder
//       );
//       if (result && result.response && result.response.status === 303) {
//         this.setState({
//           outOfStockProduct: result.response.data.message,
//           isOutOfStock: true,
//         });
//       }
//     } else {
//       shopStore!.displayPaymentDialog();
//     }
//   };

//   render() {
//     // if (shopStore!.loading) {
//     //     return <Loading show={true} />
//     // }

//     return (
//       <div className="order-review-fullheight">
//         {/* {shopStore!.showPaymentDialog && this._renderPaymentModal()}
//                 {this.state.isOutOfStock && this._renderOutOfStockModal()} */}
//         {this._renderContent()}
//         <div className="m-20">
//           {this._renderSum()}
//           <YellowButton label="Jetzt kaufen" onClick={this._onBuyNow} />
//         </div>
//         {/* <Header backEnabled customBack={() => {
//                     shopStore!.hidePaymentDialog();
//                     shopStore!.setOrderNumber("");
//                     goBack();
//                 }} /> */}
//       </div>
//     );
//   }
// }

// export default OrderReview;

// // const mapDispatchToProps = (dispatch: any) => {
// //     return {
// //         navigateTo(path: string) {
// //             dispatch(push(path));
// //         },
// //         goBack() {
// //             dispatch(goBack())
// //         },
// //         back() {
// //             dispatch(push('/home'));
// //         }
// //     };
// // };
// // export default connect(
// //     null,
// //     mapDispatchToProps
// // )(OrderReview);
