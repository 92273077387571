import React, { Component, useEffect, useState } from "react";
import "./PersonalSpace.css";
import Header from "../../../components/header/Header";
import TextInput from "../../../components/TextInput/TextInput";
import plus from "../../../assets/plus-green.png";
import minus from "../../../assets/remove_red.png";

import Swal from "sweetalert2/dist/sweetalert2.js";

import { getAddressFromPlace } from "../../../services/GetAddressFromGooglePlace.service";
import placeholder from "../../../assets/profilbild_placeholder.png";
import Loading from "../../../components/loading/Loading";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import TeamHelper from "../../../services/TeamHelper.service";
import StrapiHelper from "../../../services/StrapiHelper.service";
import loading_white from "../../../assets/mohren-loader-weiss.gif";

interface PersonalSpaceProps {
  userStore?: any;
}

const PersonalSpace = ({ userStore }: PersonalSpaceProps) => {
  const user = userStore.currentUser;

  const errorMessageAddress = "Bitte geben Sie Postleitzahl und Ort ein!";
  const errorMessageFname = "Ihr Vorname darf nicht über 15 Zeichen besitzen!";
  const errorMessageLname = "Ihr Nachname darf nicht über 15 Zeichen besitzen!";

  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState("");

  const [fname, setFname] = useState(user.fname || "");
  const [lname, setLname] = useState(user.lname || "");
  const [image, setImage] = useState(user.image || "");
  const [street, setStreet] = useState(user.street || "");
  const [plz, setPlz] = useState(user.plz || "");
  const [location, setLocation] = useState(user.location || "");
  const [country, setCountry] = useState("Österreich");
  const [loading, setLoading] = useState(false);

  function checkEntryChange(input: any, entry: any) {
    if (entry != input) {
      if (entry == null && input == "") {
        return false;
      }
      return true;
    }

    return false;
  }

  function dataChanged() {
    if (
      checkEntryChange(fname, user.fname) ||
      checkEntryChange(lname, user.lname) ||
      checkEntryChange(street, user.street) ||
      checkEntryChange(plz, user.plz) ||
      checkEntryChange(location, user.location)
    ) {
      return true;
    }
    return false;
  }

  function imageChanged() {
    return checkEntryChange(image, user.image);
  }

  function addressSafeable() {
    if (
      (street && street.length != 0) ||
      (plz && plz.length != 0) ||
      (location && location.length != 0)
    ) {
      if ((plz && plz.length === 0) || (location && location.length === 0)) {
        return false;
      }
      return true;
    }
    return true;
  }

  function nameSafeable() {
    if (fname.length > 15) {
      if (errorMessage != errorMessageFname) {
        setErrorMessage(errorMessageFname);
      }
      return false;
    }

    if (lname.length > 15) {
      if (errorMessage != errorMessageLname) {
        setErrorMessage(errorMessageLname);
      }
      return false;
    }
    return true;
  }

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  }

  function canUpdateUser() {
    if (!dataChanged() && !imageChanged()) {
      if (errorMessage != "") {
        setErrorMessage("");
      }
      return false;
    }

    if (!addressSafeable()) {
      if (errorMessage != errorMessageAddress) {
        setErrorMessage(errorMessageAddress);
      }
      return false;
    }

    if (!nameSafeable()) {
      return false;
    }

    if (errorMessage != "") {
      setErrorMessage("");
    }
    return true;
  }

  const deleteUser = async () => {
    Swal.fire({
      title: 'Bist du sicher?',
      type: 'warning',
      text: "Möchtest du dein Konto wirklich löschen? Dieser Schritt kann nicht rückgängig gemacht werden!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja, löschen!',
      cancelButtonText: 'Nein, abbrechen!'
    }).then((result) => {
      if (result && result.value) {
        userStore.deleteAccount().then(() => {
          history.push("/");
        });
      }
    })
  }

  const updateUser = async () => {
    if (!canUpdateUser()) {
      return;
    }

    if (dataChanged() || imageChanged()) {
      var userChanged = Object.assign({}, userStore.currentUser, {
        fname: fname,
        lname: lname,
        street,
        plz,
        location,
        country,
      });

      if (image) {
        userChanged["image"] = image.id;
      } else {
        userChanged["image"] = "";
      }
      await userStore.updateUser(userChanged);
    }

    history.push("/profil");
  };

  function generateMailToLink() {
    return `mailto:verwaltung@mohrenbrauerei.at?subject=Account löschen&body=Hallo Mohren Team, %0D%0DHiermit würde ich gerne meinen Mohren-App User mit dem Username "${
      userStore!.currentUser.username
    }" und der Email-Adresse "${
      userStore!.currentUser.email
    }" löschen! %0D%0DFreundliche Grüße ${
      userStore!.currentUser.fname && userStore!.currentUser.lname
        ? `%0D ${userStore!.currentUser.fname} ${userStore!.currentUser.lname}`
        : ""
    }`;
  }

  var imageOpacity = 1;

  function getImage() {
    if (loading) {
      return loading_white;
    }
    if (image) {
      imageOpacity = 1;
      return image.url;
    } else {
      if (user.player) {
        imageOpacity = 0.5;
        return TeamHelper.getByKey(user.player).portrait;
      } else {
        imageOpacity = 1;
        return placeholder;
      }
    }
  }

  function onPlaceSelected(place) {
    const address = getAddressFromPlace(place);
    setStreet(
      address.street + (address.streetNr ? " " + address.streetNr : "")
    );
    setLocation(address.city);
    setPlz(address.zip);
    setCountry(address.country);
  }
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="personal-space">
        <div className="profile-title">Profil vervollständigen</div>
        <div className="personal-space-image-container">
          <div style={{ position: "relative" }}>
            <img
              src={getImage()}
              className="personal-space-image"
              style={{ marginTop: "15px", opacity: imageOpacity }}
            />

            <div
              className="personal-space-image-btn-container"
              style={{ right: 0 }}
            >
              <img
                src={plus}
                className="personal-space-image-btn"
                style={{ right: 0 }}
                onClick={() => {}}
              />
              <input
                type="file"
                name="pic"
                accept="image/png,image/x-png,image/jpeg,image/jpg,capture=camera"
                className="personal-space-input"
                onChange={(result: any) => {
                  getBase64(result.target.files[0], async (result) => {
                    setLoading(true);
                    const response: any = await StrapiHelper.uploadImage(
                      result
                    );
                    if (response && response.status === 200) {
                      setImage(response.data[0]);
                      setLoading(false);
                    }
                  });
                }}
              ></input>
            </div>
            {image ? (
              <div
                className="personal-space-image-btn-container"
                style={{ left: 0 }}
              >
                <img
                  src={minus}
                  className="personal-space-image-btn"
                  style={{ left: 0 }}
                  onClick={() => setImage(null)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="personal-space-input-container">
          <div style={{ display: "flex" }}>
            <TextInput
              placeholder="Vorname"
              value={fname}
              onChange={(event: any) => {
                setFname(event.target.value);
              }}
            ></TextInput>
            <div style={{ width: 10 }}></div>
            <TextInput
              placeholder="Nachname"
              value={lname}
              onChange={(event: any) => {
                setLname(event.target.value);
              }}
            ></TextInput>
          </div>
          <TextInput
            placeholder="Username"
            value={
              userStore.currentUser.username
                ? userStore.currentUser.username
                : ""
            }
            onChange={(event: any) => {}}
            disabled={true}
          ></TextInput>

          <TextInput
            placeholder="E-Mail-Adresse"
            value={
              userStore.currentUser.email ? userStore.currentUser.email : ""
            }
            onChange={(event: any) => {}}
            disabled={true}
          ></TextInput>

          <TextInput
            placeholder="Straße und Hausnummer"
            value={street}
            isGoogleInput={true}
            onGooglePlaceSelected={(place: any) => onPlaceSelected(place)}
            onChange={(event: any) => {
              setStreet(event.target.value);
            }}
          ></TextInput>

          <div className="shipment-address-split-text-input">
            <TextInput
              placeholder="PLZ"
              value={plz}
              type="number"
              onChange={(event: any) => {
                setPlz(event.target.value);
              }}
            ></TextInput>
            <TextInput
              placeholder="Stadt"
              value={location}
              onChange={(event: any) => {
                setLocation(event.target.value);
              }}
            ></TextInput>
          </div>
          <TextInput
            placeholder="Land"
            value={country}
            disabled={true}
            onChange={(event: any) => {
              setCountry(event.target.value);
            }}
          ></TextInput>
          <div className="personal-space-error-mes">{errorMessage}</div>
          <div
            className={
              "personal-space-btn-edit " +
              (!canUpdateUser() && "personal-space-btn-edit-disabled")
            }
            onClick={() => {
              updateUser();
            }}
          >
            <div className="personal-space-btn-edit-text">Speichern</div>
          </div>
          <div className="delete-me-button">
            <span onClick={deleteUser}>
              Account löschen
            </span>
          </div>
        </div>
      </div>
      <Header backEnabled backUrl="/profil"></Header>
    </div>
  );
};

export default inject("userStore")(observer(PersonalSpace));
