// @ts-nocheck
import React, { useEffect, useRef } from 'react';

import Header from "../../components/header/Header";
import "./ARCamera.css";

const ARCamera = () => {
    const sceneRef = useRef(null);
    const baseUrl = window.location.origin;

    useEffect(() => {

    }, []);


    return (
        <div>
            <div className="ar-viewer" style={{ width: '100vw', height: '100vh', overflow: 'hidden'}}>
                <iframe src="https://web-ar.mohren.app/" height="100%" width="100%"  allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;"></iframe>
            </div>

            <Header backEnabled={true} backUrl="/home" goalCounterEnabled={true} />
        </div>
    )
}

export default ARCamera;