import { observable, computed, action } from "mobx";
import StrapiHelper from "../services/StrapiHelper.service";

export default class QuestionStore {
  userStore: any;
  @observable
  loading: boolean = true;
  @observable
  isInEdit: boolean = false;
  @observable
  currentQuizId: any = null;
  @observable
  currentQuestion: any = null;
  @observable
  answere: any = -1;
  @observable
  answered: boolean = false;
  @observable
  solveQuestion: boolean = false;
  @observable
  done: boolean = false;
  @observable
  correctAnswere: any = -1;
  @observable
  noQuestion: boolean = false;

  @action
  addUserStore(store: any) {
    this.userStore = store;
  }

  @action
  setAnswere(index: any) {
    this.answere = index;
    this.answered = true;
  }

  @action
  loadQuestion() {
    this.loading = true;
  }

  @action
  loadQuestionDone() {
    this.loading = false;
  }

  @action
  setQuestionCorrect() {
    this.loading = false;
  }

  @action
  setSolveQuestion() {
    this.solveQuestion = true;
  }

  @action
  setQuestionDone() {
    this.done = true;
  }

  @action
  clearQuestion() {
    this.done = false;
    this.answere = -1;
    this.answered = false;
    this.solveQuestion = false;
  }

  @action
  setCurrentQuestion(question: any) {
    this.currentQuestion = question;
  }

  @action
  setCurrentQuizId(id: any) {
    this.currentQuizId = id;
  }

  @action
  setCorrectAnswere(answere: any) {
    this.correctAnswere = answere;
  }

  @action
  setNoAnswer(noAnswere: boolean) {
    this.noQuestion = noAnswere;
  }

  @action
  validateAnswere(index: number) {
    this.setAnswere(index);
    StrapiHelper.answereQuestion(index, this.currentQuizId).then((result) => {
      this.setCorrectAnswere(result.data.correctAnswer);
      this.userStore.addPoints(result.data.newPoints);
      this.setSolveQuestion();
      setTimeout(() => {
        this.setQuestionDone();
      }, 2000);
    });
  }

  @action
  loadCurrentQuestion() {
    this.setNoAnswer(false);
    this.clearQuestion();
    this.loadQuestion();
    StrapiHelper.loadCurrentQuestion()
      .then((result) => {
        this.setCurrentQuestion(result.data.question);
        this.setCurrentQuizId(result.data.quizId);
        this.loadQuestionDone();
      })
      .catch((err) => {
        this.setNoAnswer(true);
        this.loadQuestionDone();
      });
  }
}
