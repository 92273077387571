import React, { Component, useEffect } from "react";
import Header from "../../components/header/Header";
import Moment from "react-moment";
import "moment/locale/de";
import "./EventDetails.css";
import eventImg from "../../assets/events/event.png";
import infoImg from "../../assets/events/info.png";
import ImageWithSkeleton from "../../components/ImageWithSkeleton/ImageWithSkeleton";
import Skeleton from "react-skeleton-loader";
import { observer, inject } from "mobx-react";
import EventStore from "../../mobx/EventStore";

interface EventDetailsProps {
  eventStore?: EventStore;
}

const EventDetails = ({ eventStore }: EventDetailsProps) => {
  useEffect(() => {
    window.scroll(0, 0);
    return () => {};
  }, []);

  function renderBadge() {
    return <div className="event-details-event-text">Event</div>;
  }

  function renderEventDetails() {
    return (
      <div className="event-details-container-wrapper">
        {eventStore!.currentEvent && (
          <div className="event-details-info-container">
            <img src={eventImg} className="event-details-info-image" />
            <div className="shop-detail-text">
              {eventStore!.currentEvent.location}
            </div>
          </div>
        )}
        {eventStore!.currentEvent && (
          <div className="event-details-info-container">
            <img src={infoImg} className="event-details-info-image" />
            <div className="event-detail-text">
              <Moment format="DD.MM.YYYY HH:mm">
                {eventStore!.currentEvent!.startDateTime}
              </Moment>
            </div>
          </div>
        )}
      </div>
    );
  }

  function renderDetails() {
    return (
      <div className="event-details-container">
        <ImageWithSkeleton
          image={
            eventStore!.currentEvent ? eventStore!.currentEvent!.image.url : ""
          }
          imageClassName="event-details-price-img"
          width="100%"
          height={"" + window.innerWidth + "px"}
          borderRadius="0px"
        />
        <div className="event-details-container-content">
          <div className="event-details-container-title">
            {eventStore!.currentEvent ? (
              eventStore!.currentEvent.name
            ) : (
              <Skeleton />
            )}
          </div>
          {eventStore!.currentEvent ? renderBadge() : <Skeleton />}
          {eventStore!.currentEvent ? (
            renderEventDetails()
          ) : (
            <Skeleton count={5} />
          )}
          <div className="event-details-container-description">
            {eventStore!.currentEvent &&
            eventStore!.currentEvent!.description ? (
              eventStore!.currentEvent.description
                .split("\n")
                .map((item, key) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  );
                })
            ) : (
              <Skeleton count={5} />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", paddingTop: 70 }}>
      {renderDetails()}
      <Header backEnabled />
    </div>
  );
};

export default inject("eventStore")(observer(EventDetails));
