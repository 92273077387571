import React, { Component } from "react";
import "./TextSlideDown.css";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import next from "../../assets/next.png";
import down from "../../assets/down.png";
import Linkify from "linkifyjs/react";

class TextSlideDown extends Component<{ item: any }, { open: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  componentDidMount() {}
  formatText(text) {
    return text.split("\n").map((item, i) => {
      return <p key={i}>{item}</p>;
    });
  }
  render() {
    var ww: any = window;

    return (
      <div
        className="textslide-container"
        style={{ backgroundColor: this.state.open ? "#008a4c" : "" }}
      >
        <div
          className="textslide-title-container"
          onClick={() => {
            this.setState({ open: !this.state.open });
          }}
        >
          {!this.state.open && (
            <img src={next} className="textslide-title-img-next" />
          )}
          {this.state.open && (
            <img src={down} className="textslide-title-img-next" />
          )}
          <div className="textslide-title">{this.props.item.question}</div>
        </div>

        <SlideDown closed={!this.state.open} className="text-slidedown">
          <div className="textslide-text">
            {this.formatText(this.props.item.answere)}
            {/* <Linkify
              tagName='span'
              options={{
                target: {
                  url: '_blank',
                },
                attributes: {
                  onClick: function (e) {
                    if (ww && ww.ReactNativeWebView) {
                      e.preventDefault();

                      ww.ReactNativeWebView.postMessage(
                        JSON.stringify({
                          type: 'openurl',
                          data: e.target.getAttribute('href'),
                        })
                      );
                      return false;
                    }
                  },
                },
              }}
            >
              {this.formatText(this.props.item.answere)}
            </Linkify> */}
          </div>
        </SlideDown>
      </div>
    );
  }
}

export default TextSlideDown;
