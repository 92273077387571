import React, { useCallback, useState } from "react";
import "./CropRodal.css";
import { isMobile } from "react-device-detect";
import Rodal from "rodal";
import Cropper from "react-easy-crop";
import { TitleHeaderGreen } from "../../../../components/title-header-green/TitleHeaderGreen";
import YellowButton from "../../../../components/Yellow-Button/Yellow-Button";
import { getCroppedImg } from "./cropimage";

interface CropRodalProps {
  image: string;
  onPhotoTaken: Function;
  closeRodal: Function;
}

let cropRodalStyle = {
  width: "auto",
  height: "calc(100% - 40px)",
  padding: 15,
  borderRadius: "15px",
  marginLeft: "10px",
  marginRight: "10px",
  background: isMobile ? "#008a4c" : "transparent",
  boxShadow: "none",
};

const CropRodal = ({ image, onPhotoTaken, closeRodal }: CropRodalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <Rodal
      visible={image != ""}
      animation={"slideUp"}
      duration={500}
      customStyles={cropRodalStyle}
      className="slideup-modal"
      showMask={false}
      showCloseButton={false}
      onClose={() => {
        //  this.setState({ isVisible: false });
      }}
    >
      <TitleHeaderGreen title="Foto zuschneiden" subtitle="" />
      <div className="cropper-container">
        <Cropper
          minZoom={0.4}
          image={image}
          crop={crop}
          zoom={zoom}
          restrictPosition={false}
          aspect={1}
          rotation={rotation}
          onCropChange={(crop) => {
            setCrop(crop);
          }}
          onCropComplete={onCropComplete}
          onZoomChange={(zoom) => {
            setZoom(zoom);
          }}
          onRotationChange={(rotation) => {
            setRotation(rotation);
            //   this.setState({ rotation });
          }}
        />
      </div>
      <YellowButton
        label="Foto verwenden"
        onClick={async () => {
          const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
          onPhotoTaken(croppedImage);
          closeRodal();
        }}
      />
      <YellowButton
        label="Abbrechen"
        style={{ marginTop: 15, marginBottom: 25 }}
        onClick={async () => {
          setCroppedAreaPixels(null);
          setZoom(0);
          setRotation(0);
          closeRodal();
        }}
      />
    </Rodal>
  );
};

export default CropRodal;
