import React, { Component, useEffect } from 'react'
import './Maintenance.css'
import logo from "../../assets/logo_new_small.svg";

import Container from '../Container/Container'

function Maintenance() {
  return (
    <Container>
      <div className='maintenance'>
        <img className='logo' src={logo} width='200px' alt='logo' />
        <h1 className="maintenance-title">Wartungsarbeiten</h1>
        <p className="maintenance-text">Wir sind gerade dabei, die App zu aktualisieren.<br/>Bitte versuche es später noch einmal.</p>
      </div>
    </Container>
  )
}

export default Maintenance
