import React, { Component, useEffect, useState } from "react";
import "./ShipmentAddress.css";
import { inject, observer } from "mobx-react";
import ShopStore from "../../../mobx/ShopStore";
import Header from "../../../components/header/Header";
import YellowButton from "../../../components/Yellow-Button/Yellow-Button";
import { TitleHeaderGreen } from "../../../components/title-header-green/TitleHeaderGreen";
import TextInput from "../../../components/TextInput/TextInput";
import CheckBox from "rc-checkbox";
import { getAddressFromPlace } from "../../../services/GetAddressFromGooglePlace.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TextArea from "../../../components/TextArea/TextArea";
import UserStore from "../../../mobx/UserStore";
import { useHistory } from "react-router-dom";
import WhiteButton from "../../../components/White-Button/White-Button";

interface ShipmentAddressProps {
  shopStore?: ShopStore;
  userStore?: UserStore;
}

const ShipmentAddress = ({ shopStore, userStore }: ShipmentAddressProps) => {
  const user = userStore!.currentUser;
  const history = useHistory();

  const [fname, setFname] = useState(user.fname || "");
  const [lname, setLname] = useState(user.lname || "");
  const [street, setStreet] = useState(user.street || "");
  const [plz, setPlz] = useState(user.plz || "");
  const [location, setLocation] = useState(user.location || "");
  const [country, setCountry] = useState("Österreich");
  const [isPickup, setIsPickup] = useState(false);
  const [personalText, setPersonalText] = useState("");

  useEffect(() => {
    if (!shopStore!.currentOrder) {
      history.push("/home");
    } else if (shopStore!.currentOrder.personalText) {
      setPersonalText(shopStore!.currentOrder.personalText);
    }
  }, []);

  function _onPlaceSelected(place) {
    const address = getAddressFromPlace(place);
    setStreet(
      address.street + (address.streetNr ? " " + address.streetNr : "")
    );
    setLocation(address.city);
    setPlz(address.zip);
    setCountry(address.country);
  }

  function canContinue() {
    if (isPickup && fname && lname) {
      return true;
    } else if (
      fname != "" &&
      lname != "" &&
      street != "" &&
      plz != 0 &&
      location != "" &&
      country != ""
    ) {
      return true;
    }
    return false;
  }

  function onContinue() {
    let order = shopStore!.currentOrder;
    if (order) {
      if (isPickup) {
        order.isPickup = isPickup;
        order.deliveryCosts = 0.0;
        if (
          !userStore!.currentUser.fname ||
          userStore!.currentUser.fname === "" ||
          !userStore!.currentUser.lname ||
          userStore!.currentUser.lname === ""
        ) {
          var userNamesChanged = Object.assign({}, userStore!.currentUser, {
            fname: fname,
            lname: lname,
          });
          userStore!.updateUser(userNamesChanged);
        }
      } else {
        var hasUserChanged = false;
        order.address = {
          street: street,
          plz: plz,
          location: location,
          country: country,
        };
        order.isPickup = isPickup;
        var userChanged = userStore!.currentUser;
        if (
          !userStore!.currentUser.fname ||
          userStore!.currentUser.fname === "" ||
          !userStore!.currentUser.lname ||
          userStore!.currentUser.lname === ""
        ) {
          userChanged.fname = fname;
          userChanged.lname = lname;
          hasUserChanged = true;
        }
        if (
          (!userStore!.currentUser.street ||
            userStore!.currentUser.street === "") &&
          (!userStore!.currentUser.plz || userStore!.currentUser.plz === "") &&
          (!userStore!.currentUser.location ||
            userStore!.currentUser.location === "")
        ) {
          userChanged.street = street;
          userChanged.plz = plz;
          userChanged.location = location;
          hasUserChanged = true;
        }

        if (hasUserChanged) {
          userStore!.updateUser(userChanged);
        }
      }

      order.fname = fname;
      order.lname = lname;
      order.user.fname = fname;
      order.user.lname = lname;
      order.personalText = personalText;
      shopStore!.setCurrentOrder(order);
    } else {
      history.push("/home");
    }

    history.push("/orderreview");
  }

  function renderCheckbox() {
    return (
      <div
        className="pickup-checkbox-container"
        onClick={() => {
          setIsPickup(!isPickup);
          shopStore!.setPickUp(isPickup);
        }}
      >
        <CheckBox checked={isPickup} />
        <div>
          Ich möchte die Lieferung selber bei der Mohrenbrauerei in Dornbirn
          abholen.
        </div>
      </div>
    );
  }

  function hasPersonalText() {
    var hasPersonalText = false;
    if (shopStore!.currentOrder && shopStore!.currentOrder!.orderparts) {
      shopStore!.currentOrder!.orderparts.forEach((orderPart) => {
        if (orderPart.product.hasPersonalText) {
          hasPersonalText = true;
        }
      });
    }

    return hasPersonalText;
  }

  function renderContent() {
    return (
      <div className="shipment-address">
        <TitleHeaderGreen title="Lieferadresse" subtitle="" />
        {renderCheckbox()}
        <div className="shipment-address-body">
          <form>
            <div className="shipment-address-split-text-input">
              <TextInput
                placeholder="Vorname"
                value={fname}
                style={{ marginRight: 5 }}
                onChange={(event: any) => {
                  setFname(event.target.value);
                }}
              ></TextInput>
              <TextInput
                placeholder="Nachname"
                value={lname}
                style={{ marginLeft: 5 }}
                onChange={(event: any) => {
                  setLname(event.target.value);
                }}
              ></TextInput>
            </div>

            <TextInput
              placeholder="Straße und Hausnummer"
              value={street}
              disabled={isPickup}
              isGoogleInput={true}
              onGooglePlaceSelected={(place: any) => _onPlaceSelected(place)}
              onChange={(event: any) => {
                setStreet(event.target.value);
              }}
            ></TextInput>

            <div className="shipment-address-split-text-input">
              <TextInput
                placeholder="PLZ"
                value={plz}
                type="number"
                disabled={isPickup}
                style={{ marginRight: 5 }}
                onChange={(event: any) => {
                  setPlz(event.target.value);
                }}
              ></TextInput>
              <TextInput
                placeholder="Stadt"
                value={location}
                disabled={isPickup}
                style={{ marginLeft: 5 }}
                onChange={(event: any) => {
                  setLocation(event.target.value);
                }}
              ></TextInput>
            </div>
            <TextInput
              placeholder="Land"
              value={country}
              style={{ marginRight: 5 }}
              disabled={true}
              onChange={(event: any) => {
                setCountry(event.target.value);
              }}
            ></TextInput>
            {!isPickup && (
              <div className="shipment-address-delivery-costs">
                Achtung es fallen Lieferkosten beim Versand an.
              </div>
            )}
            {hasPersonalText() && (
              <>
                <div className="title">Persönliche Nachricht (optional)</div>
                <TextArea
                  placeholder="Deine persönliche Nachricht."
                  value={personalText}
                  onChange={(event: any) => {
                    setPersonalText(event.target.value);
                  }}
                />
              </>
            )}
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="shipment-address-container">
      {renderContent()}
      <WhiteButton
        disabled={!canContinue()}
        label="Weiter zur Bezahlung"
        action={() => {
          if (canContinue()) {
            onContinue();
          }
        }}
      />
      <Header backEnabled />
    </div>
  );
};

export default inject("shopStore", "userStore")(observer(ShipmentAddress));
