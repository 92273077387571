import React, { useEffect, useState } from "react";
import "./ImageTaker.css";
import { isMobile } from "react-device-detect";
import Rodal from "rodal";
import Webcam from "react-webcam";
import YellowButton from "../../../../components/Yellow-Button/Yellow-Button";
import { inject } from "mobx-react";
import CustomTshirtIcon from "../../../../assets/custom_tshirt_preview_old.png";
import { TitleHeaderGreen } from "../../../../components/title-header-green/TitleHeaderGreen";
import cross from "../../../../assets/cross.png";
import loading_white from "../../../../assets/mohren-loader-weiss.gif";
import ShopStore from "../../../../mobx/ShopStore";
import Container from "../../../../components/Container/Container";

interface ImageTakerProps {
  visible: boolean;
  shopStore?: ShopStore;
  onPhotoTaken: Function;
  closeRodal: Function;
}

const rodalStyle = {
  maxWidth: 650,
  width: "100%",
  height: "100%",
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  margin: "auto",
  padding: 0,
  background: "#b40e0e",
  top: "unset",
  bottom: 0,
  boxShadow: "none",
  overflow:'auto'
};

const ImageTaker = ({
  visible,
  shopStore,
  onPhotoTaken,
  closeRodal,
}: ImageTakerProps) => {
  const [loading, setLoading] = useState(true);
  const webcamRef = React.useRef(null);
  const width = window.innerWidth - 60;
  const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: "user",
  };
  const capture = React.useCallback(() => {
    //@ts-ignore
    const imageSrc = webcamRef.current.getScreenshot({
      width: 1920,
      height: 1920,
      aspectRatio: 1,
    });
    onPhotoTaken(imageSrc);
    closeRodal();
  }, [webcamRef]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <Rodal
      visible={visible}
      animation={"slideUp"}
      duration={500}
      customStyles={rodalStyle}
      className="slideup-modal"
      showMask={false}
      showCloseButton={false}
      onClose={() => {
        closeRodal();
      }}
    >
      <div style={{ overflow: "auto" }}>
        <div className="close-container">
          <img
            src={cross}
            className="close"
            onClick={() => {
              closeRodal();
            }}
          />
        </div>
        <TitleHeaderGreen
          title="Foto aufnehmen"
          subtitle="Mach ein Foto von dir im Seitenprofil. Das Foto wird dann ausgeschnitten und angepasst."
        />
        <div
          style={{
            overflow: "hidden",
            borderRadius: 8,
            height: "100%",
            maxHeight: 500,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="cap-container"
            style={{
              height: window.innerWidth < 500 ? window.innerWidth : 500,
            }}
          >
            <img
              src={
                shopStore!.currentShopItem &&
                shopStore!.currentShopItem.specialImageBackground
                  ? shopStore!.currentShopItem.specialImageBackground.url
                  : CustomTshirtIcon
              }
              className="underlay"
            />
            <div className="underlay-camera">
              <div className="border-webcam">
                <div
                  style={{
                    borderRadius: 50,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <img src={loading_white} className="loading" />
                  {visible && (
                    <div className="video-container">
                      <Webcam
                        audio={false}
                        height={92}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={92}
                        minScreenshotHeight={1280}
                        minScreenshotWidth={1280}
                        videoConstraints={videoConstraints}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: 15 }}>
          <YellowButton
            label="Foto aufnehmen"
            loading={loading}
            style={{ marginTop: 15 }}
            onClick={capture}
          />
        </div>
      </div>
    </Rodal>
  );
};

export default inject("shopStore")(ImageTaker);
