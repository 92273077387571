import React, { Component } from 'react';
import './ShopCart.css';

import OrderPart from '../../models/OrderPart';
import Product from '../../models/Product';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

interface ShopCardProps {
  item?: Product;
  orderPart?: OrderPart;
  pickup?: any;
  hideTitle?: boolean;
  displayOnly?: boolean;
  style?: any;
}

const ShopCart = ({
  item,
  orderPart,
  pickup,
  hideTitle,
  displayOnly,
  style,
}: ShopCardProps) => {
  const history = useHistory();

  function renderShopCard() {
    if (item) {
      let imageUrl = '';

      if (item && item.previewImage) {
        imageUrl = item.previewImage.formats.small.url;
      }
      return (
        <div
          className='shop-card'
          style={{
            ...style,
            backgroundColor: 'rgb(0, 138, 76)',
          }}
          onClick={() => {
            if (!displayOnly) {
              history.push('/product/' + item.id);
            }
          }}
        >
          {!hideTitle && (
            <div className='shop-card-header'>
              <div className='shop-card-header-title'> {item.name}</div>
              <div className='shop-card-header-subtitle'>
                {item.shortDescription}
              </div>
            </div>
          )}
          <div className='shop-card-content'>
            <img src={imageUrl} />
          </div>
        </div>
      );
    }
    return <div></div>;
  }

  function renderPickupCard() {
    if (pickup) {
      let imageUrl = '';
      if (pickup.product && pickup.product.previewImage) {
        imageUrl = pickup.product.previewImage.formats.small.url;
      }
      const pickedUpStyle =
        pickup.status === 'Pickedup' ? { opacity: 0.5 } : {};
      if (pickup.status != 'Locked' && pickup.product) {
        return (
          <div
            className='shop-card'
            style={{
              ...style,
              backgroundColor: 'rgb(0, 138, 76)',
              ...pickedUpStyle,
            }}
            onClick={() => {
              if (!displayOnly) {
                history.push('/product/' + pickup.product.id);
              }
            }}
          >
            {!hideTitle && (
              <div className='shop-card-header'>
                <div className='shop-card-header-title'>
                  {pickup.product.name}
                </div>
                <div className='shop-card-header-subtitle'>
                  {pickup.product.shortDescription}
                </div>
              </div>
            )}
            <div className='shop-card-content'>
              <img src={imageUrl} />
            </div>
            <div className='shop-card-header'>
              {pickup.status === 'Active' ? (
                <div className='shop-card-header-title'>
                  Abholcode: {pickup.code}
                </div>
              ) : pickup.status === 'Pickedup' ? (
                <div className='shop-card-header-title'>bereits abgeholt</div>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      }
    }
    return <div></div>;
  }

  function renderImageForOrderPart() {
    let imageUrl = '';
    if (orderPart!.image) {
      imageUrl = orderPart!.image.url;
    } else if (
      orderPart &&
      orderPart.product &&
      orderPart.product.previewImage
    ) {
      imageUrl = orderPart.product.previewImage.url;
    }
    return (
      <div className='custom-trikot'>
        <img src={imageUrl} />
      </div>
    );
  }

  function renderOrderPart() {
    if (orderPart) {
      return (
        <div
          className='shop-card'
          style={{
            ...style,
            backgroundColor: '#ffffff',
          }}
          onClick={() => {
            if (!displayOnly) {
              history.push('/product/' + orderPart.product.id);
            }
          }}
        >
          {!hideTitle && (
            <div className='shop-card-header'>
              {orderPart.product.previewImage.url}
            </div>
          )}
          <div
            className='shop-card-content'
            style={{
              ...style,
            }}
          >
            {renderImageForOrderPart()}
          </div>
        </div>
      );
    }
    return <div></div>;
  }

  return (
    <div>
      {item && renderShopCard()}
      {orderPart && renderOrderPart()}
      {pickup && renderPickupCard()}
    </div>
  );
};

export default observer(ShopCart);
