import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './CardSection.css'
import poweredByStripe from '../../../assets/powered_by_stripe.png'
import StripeError from '../../../models/StripeError';

interface CardSectionProps {
  error: StripeError | null,
  onChange: () => void
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const STRIPE_ERROR_TRANSLATIONS = {
  invalid_number: "Diese Kreditkartennummer ist ungültig.",
  incomplete_number: "Diese Kreditkartennummer ist unvollständig.",
  incomplete_expiry: "Das Ablaufdatum der Karte ist unvollständig.",
  incomplete_cvc: "Der Sicherheitscode ist unvollständig.",
  incomplete_zip: "Die Postleitzahl ist unvollständig."
}

function CardSection(props: CardSectionProps) {

  var errorMsg = props.error && STRIPE_ERROR_TRANSLATIONS[props.error.code]
  if (!errorMsg) {
    errorMsg = props.error ? props.error.message : ""
  }

  return (
    <div className="card-section">
      <div className="card-section-title">Kreditkarte</div>
      <div className="card-section-subtitle">Gib deine Kreditkartendetails an, um die Produkte zu bezahlen. </div>
      <div className="card-section-stripe">
        {props.error && <div className="card-section-error">{errorMsg}</div>}
        {!props.error && <div className="card-section-no-error">{errorMsg}</div>}
        <CardElement options={CARD_ELEMENT_OPTIONS} onChange={props.onChange} />
        <div className="powered-by-stripe"><img src={ poweredByStripe } alt="Powered by Stripe" /></div>
      </div>
    </div>
  );
};

export default CardSection;