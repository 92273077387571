import React, { useEffect } from "react";
import "./Login.scss";
import { inject, observer } from "mobx-react";
import UserStore from "../../mobx/UserStore";
import WhiteButton from "../../components/White-Button/White-Button";
import logo from "../../assets/mohren_logo_new.svg";
import { useHistory } from "react-router-dom";
import AppleLoginButton from "../../components/AppleLoginButton/AppleLoginButton";

interface LoginPageProps {
  userStore?: UserStore;
}

function iOS() {
  return false;
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

console.log(navigator.platform);

const LoginPage = ({ userStore }: LoginPageProps) => {
  const history = useHistory();

  useEffect(() => {
    userStore!.navigation = history;

    if (localStorage.getItem("user") || localStorage.getItem("mohren_token")) {
      history.push("/home");
    }
  }, []);

  useEffect(() => {
    const loginFB = async () => {
      if (await userStore!.loginWithFacebook(window.location.search)) {
        if (userStore!.currentUser && userStore!.currentUser.username) {
          history.push("/home");
        } else {
          history.push("/username");
        }
      }
    };

    const loginApple = async () => {
      if (await userStore!.loginWithApple(window.location.search)) {
        if (userStore!.currentUser && userStore!.currentUser.username) {
          history.push("/home");
        } else {
          history.push("/username");
        }
      }
    };

    if (
      window.location.search.includes("access_token") &&
      window.location.search.includes("bearer")
    ) {
      loginFB();
    }
    if (window.location.search.includes("code")) {
      loginApple();
    }
  }, []);

  const debounce = (func, wait) => {
    let timeout;

    const later = () => {
      clearTimeout(timeout);
      func();
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
  var waiting = false; // Initially, we're not waiting
  function throttle(callback, limit) {
    // We return a throttled function
    if (!waiting) {
      // If we're not waiting
      callback(); // Execute users function
      waiting = true; // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        waiting = false; // And allow future invocations
      }, limit);
    }
  }

  const performFacebookLogin = () => {
    const login = () => {
      if (typeof window !== "undefined") {
        window.location.href = "https://cms.mohren.app/connect/facebook";
      }
    };
    throttle(login, 1000);
  };

  return (
    <div className="main-login-page">
      <script
        id='CookieDeclaration'
        src='https://consent.cookiebot.com/319048b6-c5c8-43b9-878f-17f9ec7a05dc/cd.js'
        type='text/javascript'
        async
      ></script>
      {/* <Header /> */}
      <div className="info-block">
        <div className="center-image m-20">
          <img className="info-block-image" src={logo} />
        </div>
        <div className="info-block-info">
          <h1>Willkommen in der Mohrenbräu App.</h1>
          <h3 className="text-body mbt-30">
            Werde Teil der Mohrenbräu Community und erhalte <br />
            Einblicke in die Welt von Vorarlberg‘s führender <br />
            Privatbrauerei. #dasvorarlbergerbier <br />
          </h3>
          <div>
            {/*<button*/}
            {/*  className="btn-facebooklogin"*/}
            {/*  onClick={() => {*/}
            {/*    performFacebookLogin();*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Mit Facebook anmelden*/}
            {/*</button>*/}

            {iOS() && <AppleLoginButton />}
            <WhiteButton
              label="Mit E-Mail registrieren"
              action={() => {
                history.push("emailregistration");
              }}
            />
            <div className="btn-emaillogin">
              Du bist bereits registriert?
              <br />
              <div
                className="link-u"
                onClick={() => {
                  history.push("emaillogin");
                }}
              >
                Hier anmelden
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("userStore")(observer(LoginPage));
