import React, { Component } from 'react'
import './Overlay.css'
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';


class Overlay extends Component<{ isOpen: boolean, component: any, margin?: number, height?: string, animation?: boolean, innerPadding?: number }, {}> {
    private rodalStyle = {
        width: '100%',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        padding: 0,
        top: 15,
        bottom: 15,
        height: '98%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        margin: 0
    }
    componentDidMount() {
    }

    render() {
        return (
            <Rodal visible={this.props.isOpen} onClose={() => { }} className="overlay" customStyles={this.rodalStyle} showCloseButton={false} animation={"zoom"} duration={this.props.animation ? 300 : 0}>
                <div className="modal-container-big">
                    <div style={{
                        height: this.props.height ? this.props.height : '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}>
                        <div className="modal-container-big-container" style={{ backgroundColor: '#007234', borderRadius: 8, padding: this.props.innerPadding == 0 ? 0 : 15, margin: 15 }}>
                            {this.props.component}
                        </div>

                    </div>
                </div>

            </Rodal >
        )
    }
}

export default Overlay;