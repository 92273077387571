import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import * as serviceWorker from './serviceWorker';

function getHashValue(key) {
    // eslint-disable-next-line no-restricted-globals
    var matches = location.hash.match(new RegExp(key + '=([^&]*)'));
    return matches ? matches[1] : null;
}

declare var window: any;
//serviceWorker.register()
// Usage
window.fbAccessToken = getHashValue('access_token');

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
