import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Datenschutz from "../pages/Datenschutz/Datenschutz";
import EmailLogin from "../pages/EmailRegistration/EmailLogin";
import LoginPage from "../pages/LoginPage/LoginPage";
import EmailRegistration from "../pages/EmailRegistration/EmailRegistration";
import EmailRegistrationDone from "../pages/EmailRegistration/EmailRegistrationDone";
import Events from "../pages/Events/Events";
import AccountSetup from "../pages/Home/AccountSetup/AccountSetup";
import Home from "../pages/Home/HomePage";
import Impressum from "../pages/Impressum/Impressum";
import UsernamePage from "../pages/LoginPage/Username/Username.page";
import OrderHistory from "../pages/Profil/OrderHistory/OrderHistory";
import PersonalSpace from "../pages/Profil/PersonalSpacePage/PersonalSpace";
import Profil from "../pages/Profil/Profil";
import Shop from "../pages/Shop/Shop";
import Teilnahmebedingung from "../pages/Teilnahmebedingung/Teilnahmebedingung";
import PrivateRoute from "./PrivateRoute";
import ShoppingCart from "../pages/Shop/ShoppingCart/ShoppingCart";
import ProductDetail from "../pages/Shop/ProductDetail/ProductDetail";
import ProductWinPage from "../pages/Profil/ProductWinPage/ProductWinPage";
import ShipmentAddress from "../pages/Shop/ShipmentAddress/ShipmentAddress";
import OrderReview from "../pages/Shop/OrderReview/OrderReview";
import EventDetails from "../pages/EventDetails/EventDetails";
import OrderDone from "../pages/Shop/OrderDone/OrderDone";
import ProductDetailCustomBox from "../pages/Shop/ProductDetail/ProductDetailCustomBox/ProductDetailCustomBox";
import EmailForgotPassword from "../pages/EmailRegistration/EmailForgotPassword";
import PasswordRecovery from "../pages/PasswordRecovery/PasswordRecovery";
import FAQ from "../pages/FAQ/FAQ";
import NewsDetails from "../pages/NewsDetails/NewsDetails";
import NotFound from "../pages/NotFound/NotFound";
import ARCamera from "../pages/ARCamera/ARCamera";
import TeilnahmebedingungPublic from "../pages/TeilnahmebedingungPublic/TeilnahmebedingungPublic";
import DatenschutzPublic from "../pages/DatenschutzPublic/DatenschutzPublic";
interface MohrenRouterProps {}

const MohrenRouter = ({}: MohrenRouterProps) => {
  return (
    <Router>
      <Switch>
        {/** all can access this pages */}
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/apple" component={LoginPage} />
        <Route path="/emailregistration" component={EmailRegistration} />
        <Route path="/emaillogin" component={EmailLogin} />
        <Route path="/forgotpw" component={EmailForgotPassword} />
        <Route path="/password-recovery" component={PasswordRecovery} />
        <Route path="/agb" component={TeilnahmebedingungPublic} />
        <Route
          path="/emailregistrationdone"
          component={EmailRegistrationDone}
        />
        {/** only auth user can access */}
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/events" component={Events} />
        <PrivateRoute path="/profil" component={Profil} />
        <PrivateRoute
          path="/teilnahmebedingung"
          component={Teilnahmebedingung}
        />
        <PrivateRoute path="/impressum" component={Impressum} />
        <Route path="/privacy" component={DatenschutzPublic} />
        <PrivateRoute path="/datenschutz" component={Datenschutz} />
        <PrivateRoute path="/orderhistory" component={OrderHistory} />
        <PrivateRoute path="/username" component={UsernamePage} />
        <PrivateRoute path="/eventdetails" component={EventDetails} />
        <PrivateRoute path="/accountsetup" component={AccountSetup} />
        <PrivateRoute path="/personalspace" component={PersonalSpace} />
        <PrivateRoute path="/ar-camera" component={ARCamera} />
         <PrivateRoute path="/wonpickups" component={ProductWinPage} />
        <PrivateRoute path="/shoppingcart" component={ShoppingCart} />
        <PrivateRoute path="/store" component={Shop} />
        <PrivateRoute path="/product/:productId" component={ProductDetail} />
        <PrivateRoute path="/newsdetail/:newsId" component={NewsDetails} />
        <PrivateRoute path="/faq" component={FAQ} />
        <PrivateRoute
          path="/customproduct/:productId"
          component={ProductDetailCustomBox}
        />

        <PrivateRoute path="/shipmentaddress" component={ShipmentAddress} />
        <PrivateRoute path="/orderreview" component={OrderReview} />
        <PrivateRoute path="/orderdone" component={OrderDone} />
        <Route path="*" component={NotFound} />

      </Switch>

      {window.innerWidth < 650 && <Navbar />}
      {/* {window.innerWidth >= 650 && <NavbarDesktop />} */}
    </Router>
  );
};

export default MohrenRouter;
