import React from 'react';
import './NotFound.css';
import { inject, observer } from 'mobx-react';
import Header from '../../components/header/Header';
import { useHistory } from 'react-router-dom';
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import YellowButton from '../../components/Yellow-Button/Yellow-Button';

interface NotFoundProps {}

const NotFound = ({}: NotFoundProps) => {
  const history = useHistory();

  return (
    <div className='parent'>
      <div className='container'>
        <div className='faq'>
          <TitleHeaderGreen title='Seite nicht gefunden' subtitle='' />
          <div style={{ 'padding': '20px'}}>
            <YellowButton
              label={'Zurück zur Startseite'}
              onClick={() => history.push('/home')}
            />
          </div>

          <Header backEnabled/>
        </div>
      </div>
    </div>
  );
};

export default inject('userStore')(observer(NotFound));
