import { observable, computed, action, toJS } from 'mobx';
import Story from '../models/Story';
import StrapiHelper from '../services/StrapiHelper.service';

export default class StoryStore {
  userStore: any;

  @observable
  stories: Story[] = [];

  @observable
  openStory?: Story;

  @observable
  storyIndex: number = 0;

  @action
  addUserStore(store: any) {
    this.userStore = store;
  }

  @action
  showStory(story: Story) {
    for (let i = 0; i < this.stories.length; i++) {
      if (this.stories[i].id == story.id) {
        this.storyIndex = i;
        break;
      }
    }
    this.openStory = story;
  }

  @action
  showPreviousStory() {
    this.storyIndex--;
    if (this.storyIndex < 0) {
      this.storyIndex = 0;
      this.hideAllStories();
    } else {
      this.openStory = this.stories[this.storyIndex];
    }
  }

  @action
  showNextStory() {
    this.storyIndex++;
    if (this.storyIndex >= this.stories.length) {
      this.storyIndex = 0;
      this.hideAllStories();
    } else {
      this.openStory = this.stories[this.storyIndex];
    }
  }

  reworkStoriesWatched(stories: Story[], reorder: boolean) {
    let newStoriesArr: Story[] = [];
    let watchedStoriesArr: Story[] = [];

    let watchedStories = this.getWatchedStories();
    for (var i = 0; i < stories.length; i++) {
      if (watchedStories.includes(stories[i].id)) {
        stories[i].watched = true;
      }
      if (reorder) {
        if (stories[i].watched) {
          watchedStoriesArr.push(stories[i]);
        } else {
          newStoriesArr.push(stories[i]);
        }
      }
    }

    if (reorder) {
      return [...newStoriesArr, ...watchedStoriesArr];
    }

    return stories;
  }

  saveWatchedStories(watchedStories) {
    this.userStore.setWatchedStories(watchedStories);
  }

  getWatchedStories() {
    // let watchedStories = [4, 6, 7];
    let watchedStories: number[] = [];
    try {
      watchedStories = toJS(this.userStore.currentUser.watchedStories) || [];
    } catch (exp) {}
    return watchedStories;
  }

  @action
  async setStoryWatched(story: Story) {
    let watchedStories = this.getWatchedStories();
    if (!watchedStories.includes(story.id)) {
      watchedStories.push(story.id);
      this.saveWatchedStories(watchedStories);
      this.stories = this.reworkStoriesWatched(this.stories, false);
    }
  }

  @action
  hideAllStories() {
    if (this.openStory) {
      this.setStoryWatched(this.openStory);
    }
    this.openStory = undefined;
    this.stories = this.reworkStoriesWatched(this.stories, true);
  }

  @action
  async loadStories() {
    let res = await StrapiHelper.loadStories();
    let stories = res.data as Story[];
    this.stories = this.reworkStoriesWatched(stories, true);
  }
}
