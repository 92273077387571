import hülsaFullCard from "../assets/players/full_cards/hülsa_full.png";
import kantaFullCard from "../assets/players/full_cards/kanta_full.png";
import kolbaFullCard from "../assets/players/full_cards/kolba_full.png";
import pfohlFullCard from "../assets/players/full_cards/pfohl_full.png";
import rotaBaronFullCard from "../assets/players/full_cards/rota_baron_full.png";
import roteBombaFullCard from "../assets/players/full_cards/rote_bomba_full.png";
import roteRaketaFullCard from "../assets/players/full_cards/rote_raketa_full.png";
import einzigWahreFullCard from "../assets/players/full_cards/einzig_wahre_full.png";
import rotePatronaFullCard from "../assets/players/full_cards/rote_patrona_full.png";
import roteForellaFullCard from "../assets/players/full_cards/rote_forella_full.png";
import heblFullCard from "../assets/players/full_cards/hebl_full.png";

import hülsaPortrait from "../assets/players/portraits/hülsa_portrait.jpg";
import kantaPortrait from "../assets/players/portraits/kanta_portrait.jpg";
import kolbaPortrait from "../assets/players/portraits/kolba_portrait.jpg";
import pfohlPortrait from "../assets/players/portraits/pfohl_portrait.jpg";
import rotaBaronPortrait from "../assets/players/portraits/rota_baron_portrait.jpg";
import roteBombaPortrait from "../assets/players/portraits/rote_bomba_portrait.jpg";
import roteRaketaPortrait from "../assets/players/portraits/rote_raketa_portrait.jpg";
import einzigWahrePortrait from "../assets/players/portraits/einzig_wahre_portrait.jpg";
import rotePatronaPortrait from "../assets/players/portraits/rote_patrona_portrait.jpg";
import roteForellaPortrait from "../assets/players/portraits/rote_forella_portrait.jpg";
import heblPortrait from "../assets/players/portraits/hebl_portrait.jpg";

import einzigWahrePlayerOnly from "../assets/players/only_players/einzig_wahre_playeronly.png";
import heblPlayerOnly from "../assets/players/only_players/hebl_playeronly.png";
import hülsaPlayerOnly from "../assets/players/only_players/hülsa_playeronly.png";
import kantaPlayerOnly from "../assets/players/only_players/kanta_playeronly.png";
import kolbaPlayerOnly from "../assets/players/only_players/kolba_playeronly.png";
import pfohlPlayerOnly from "../assets/players/only_players/pfohl_playeronly.png";
import rotaBaronPlayerOnly from "../assets/players/only_players/rota_baron_playeronly.png";
import roteBombaPlayerOnly from "../assets/players/only_players/rote_bomba_playeronly.png";
import roteForellaPlayerOnly from "../assets/players/only_players/rote_forella_playeronly.png";
import rotePatronaPlayerOnly from "../assets/players/only_players/rote_patrona_playeronly.png";
import roteRaketaPlayerOnly from "../assets/players/only_players/rote_raketa_playeronly.png";

let rotePatrona = {
  portrait: rotePatronaPortrait,
  fullCardUrl: rotePatronaFullCard,
  playerOnlyUrl: rotePatronaPlayerOnly,
  key: "ROTE_PATRONA",
  pos: 11,
};

let kolba = {
  portrait: kolbaPortrait,
  fullCardUrl: kolbaFullCard,
  playerOnlyUrl: kolbaPlayerOnly,
  key: "KOLBA",
  pos: 10,
};

let roteForella = {
  portrait: roteForellaPortrait,
  fullCardUrl: roteForellaFullCard,
  playerOnlyUrl: roteForellaPlayerOnly,
  key: "ROTE_FORELLA",
  pos: 9,
};

let rotaBaron = {
  portrait: rotaBaronPortrait,
  fullCardUrl: rotaBaronFullCard,
  playerOnlyUrl: rotaBaronPlayerOnly,
  key: "ROTA_BARON",
  pos: 8,
};

let roteRaketa = {
  portrait: roteRaketaPortrait,
  fullCardUrl: roteRaketaFullCard,
  playerOnlyUrl: roteRaketaPlayerOnly,
  key: "ROTE_RAKETA",
  pos: 7,
};

let hebl = {
  portrait: heblPortrait,
  fullCardUrl: heblFullCard,
  playerOnlyUrl: heblPlayerOnly,
  key: "HEBL",
  pos: 6,
};

let hülsa = {
  portrait: hülsaPortrait,
  fullCardUrl: hülsaFullCard,
  playerOnlyUrl: hülsaPlayerOnly,
  key: "HUELSA",
  pos: 5,
};

let pfohl = {
  portrait: pfohlPortrait,
  fullCardUrl: pfohlFullCard,
  playerOnlyUrl: pfohlPlayerOnly,
  key: "PFOHL",
  pos: 4,
};

let kanta = {
  portrait: kantaPortrait,
  fullCardUrl: kantaFullCard,
  playerOnlyUrl: kantaPlayerOnly,
  key: "AKANTA",
  pos: 3,
};

let einzigWahre = {
  portrait: einzigWahrePortrait,
  fullCardUrl: einzigWahreFullCard,
  playerOnlyUrl: einzigWahrePlayerOnly,
  key: "SEINZIG_WAHRE",
  pos: 2,
};

let roteBomba = {
  portrait: roteBombaPortrait,
  fullCardUrl: roteBombaFullCard,
  playerOnlyUrl: roteBombaPlayerOnly,
  key: "ROTE_BOMBA",
  pos: 1,
};

var mohrenTeam = ([] = [
  roteBomba,
  einzigWahre,
  kanta,
  pfohl,
  hülsa,
  hebl,
  roteRaketa,
  rotaBaron,
  roteForella,
  kolba,
  rotePatrona,
]);

export default class TeamHelper {
  public static getTeamFromFront() {
    const sortedMohrenTeam = mohrenTeam.sort((a: any, b: any) =>
      a.pos < b.pos ? 1 : -1
    );
    return sortedMohrenTeam;
  }

  public static getTeamFromBack() {
    const sortedMohrenTeam = mohrenTeam.sort((a: any, b: any) =>
      a.pos > b.pos ? 1 : -1
    );
    return sortedMohrenTeam;
  }

  public static getByPos(searchPos: number) {
    return mohrenTeam.filter((teamPlayer) => {
      return teamPlayer.pos === searchPos;
    })[0];
  }

  public static getByKey(searchKey: any) {
    const img = mohrenTeam.filter((teamPlayer) => {
      return teamPlayer.key === searchKey;
    })[0];
    return img ? img : rotaBaron;
  }
}
