import React, { useState } from 'react';
import Rodal from 'rodal';
import './InfoModal.css';

interface InfoModalProps {
  title?: string;
  htmlText?: any;
  text?: string;
  buttonText: string;
  center?: boolean;
  hide?: boolean;
  onClose?: () => void;
}

const InfoModal = ({
  htmlText,
  text,
  buttonText,
  title,
  center = true,
  hide = false,
  onClose = () => {},
}: InfoModalProps) => {
  const [isVisible, setIsVisible] = useState(!hide);

  const rodalStyle = {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    textAlign: center ? 'center' : 'left',
    padding: 0,
  };

  return (
    <Rodal
      visible={isVisible}
      animation={'slideUp'}
      duration={500}
      customStyles={rodalStyle}
      className='slideup-modal'
      showMask={false}
      showCloseButton={false}
      onClose={() => {
        setIsVisible(false);
      }}
    >
      <div className='info-modal-container'>
        <div className='info-modal-content'>
          {title && <div className='info-modal-title'>{title}</div>}
          <div className='info-modal-text'>
            {htmlText ? htmlText : text ? text : ''}
          </div>
          <button
            className='yellow-button'
            onClick={() => {
              setIsVisible(false);
              onClose();
            }}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </Rodal>
  );
};

export default InfoModal;
