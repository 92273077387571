import React, { Component } from 'react';
import './DropDownSize.css';
import Select from 'react-select';

interface DropDownSizeProps {
    options: any,
    selectedOption: any,
    selectItem: Function,
    placeholder: string
}

export default class DropDownSize extends Component<DropDownSizeProps, { selectedOption: string }> {
    constructor(props) {
        super(props);
    }
    
    handleChange = selectedOption => {
        this.props.selectItem(selectedOption);
    };

    render() {
        return (
            <div className="drop-down-size">
                <Select
                    isSearchable={false}
                    placeholder={this.props.placeholder}
                    value={this.props.selectedOption}
                    onChange={this.handleChange}
                    options={this.props.options}
                    menuPosition="fixed"
                />
            </div>
        );
    }
}