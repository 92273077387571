import React, { Component } from "react";
import "./ShoppingCartItem.css";
import { inject, observer } from "mobx-react";
import ShopItem from "../../../../models/ShopItem";
import ShopCart from "../../../../components/shop-cart/ShopCart";
import ShopStore from "../../../../mobx/ShopStore";
import OrderPart from "../../../../models/OrderPart";

interface ShoppingCartItemProps {
  shopStore?: ShopStore;
  orderPart: OrderPart;
  hideDelete?: boolean;
}

function generateTitle(orderPart: any) {
  return (
    orderPart.product.name +
    (orderPart.option != "default" ? ` (${orderPart.option})` : "")
  );
}

const ShoppingCartItem = ({
  shopStore,
  orderPart,
  hideDelete,
}: ShoppingCartItemProps) => {
  let amount = 0;
  if (orderPart.amount) {
    amount = orderPart.amount;
  }
  return (
    <div className="shopping-card-item">
      <div className="shopping-card-item-image">
        <ShopCart orderPart={orderPart} displayOnly hideTitle />
      </div>
      <div className="shopping-card-information">
        <div>
          <div className="shopping-card-information-title">
            {generateTitle(orderPart)}
          </div>
          <div className="shopping-card-information-size">Menge: {amount}</div>
        </div>

        {!hideDelete && (
          <div
            className="shopping-card-information-delete"
            onClick={() => {
              shopStore!.deleteItemFromShopCard(orderPart);
            }}
          >
            Artikel löschen
          </div>
        )}

        {/** to keep the alignment of the text */}
        {/* {hideDelete && <div style={{ height: 19 }}></div>} */}
      </div>
      <div className="shopping-card-price">
        {"" + (orderPart.product.price * amount).toFixed(2).replace(".", ",")} €
      </div>
    </div>
  );
};

export default inject("shopStore")(observer(ShoppingCartItem));
