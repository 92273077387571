import React, { Component, useEffect, useState } from 'react';
import './ShoppingCart.css';
import { inject, observer } from 'mobx-react';
import ShopStore from '../../../mobx/ShopStore';
import ShopItem from '../../../models/ShopItem';
import Header from '../../../components/header/Header';
import YellowButton from '../../../components/Yellow-Button/Yellow-Button';
import { TitleHeaderGreen } from '../../../components/title-header-green/TitleHeaderGreen';
import ShoppingCartItem from './ShoppingCartItem/ShoppingCartItem';
import OrderPart from '../../../models/OrderPart';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import UserStore from '../../../mobx/UserStore';
import { useHistory } from 'react-router-dom';
import WhiteButton from '../../../components/White-Button/White-Button';

interface ShoppingCartProps {
  shopStore?: ShopStore;
  userStore?: UserStore;
}

const ShoppingCart = ({ shopStore, userStore }: ShoppingCartProps) => {
  const history = useHistory();
  useEffect(() => {
    shopStore!.loadShopCardItems();
    return () => {};
  }, []);

  function _calculatePrice() {
    let calculation = 0;
    for (let item of shopStore!.shoppingCart) {
      let amount = 0;
      if (item.amount) {
        amount = item.amount;
      }
      calculation += item.product.price * amount;
    }
    return calculation;
  }

  function renderShopItems() {
    if (shopStore!.shoppingCart.length == 0) {
      return (
        <div
          className='shopping-card-calculation-text'
          style={{ textAlign: 'center' }}
        >
          Dein Warenkorb ist leer.
        </div>
      );
    }
    return shopStore!.shoppingCart.map((item: OrderPart, key) => {
      return <ShoppingCartItem orderPart={item} key={key} />;
    });
  }

  function renderSum() {
    return (
      <div>
        <div className='shopping-card-seperator'></div>
        <div className='shopping-card-calculation'>
          <div className='shopping-card-calculation-text'>
            Summe ({shopStore!.getNumberOfCartItems()} Artikel)
          </div>
          <div className='shopping-card-calculation-text'>
            {'' + _calculatePrice().toFixed(2).replace('.', ',')} €
          </div>
        </div>
      </div>
    );
  }

  if (shopStore!.error) {
    Swal.fire({
      text: shopStore!.error.msg,
      type: 'error',
      confirmButtonColor: '#ffd42e',
      confirmButtonText: 'Ok',
    }).then(() => {
      shopStore!.clearErrer();
    });
  }
  return (
    <div className='shopping-card'>
      <TitleHeaderGreen title={'Warenkorb'} subtitle={''} />

      <div style={{ maxHeight: '70%', overflow: 'auto', flexGrow: 1 }}>
        {renderShopItems()}
      </div>
      <div>
        {renderSum()}
        <WhiteButton
          label='Zur Kasse'
          style={{ marginBottom: 20 }}
          disabled={shopStore!.shoppingCart.length == 0}
          action={() => {
            if (shopStore!.shoppingCart.length > 0) {
              shopStore!
                .checkAvailablity()
                .then(() => {
                  shopStore!.setCurrentOrder(undefined);
                  shopStore!.setCurrentOrder({
                    user: {
                      id: userStore!.currentUser.id,
                      fname: userStore!.currentUser.fname,
                      lname: userStore!.currentUser.lname,
                      email: userStore!.currentUser.email,
                    },
                    orderparts: shopStore!.shoppingCart,
                  });

                  history.push('/shipmentaddress');
                })
                .catch(() => {
                  Swal.fire({
                    text:
                      'Eines deiner Produkte haben wir in dieser Anzahl nicht mehr im Sortiment',
                    type: 'info',
                    confirmButtonColor: '#ffd42e',
                    confirmButtonText: 'Ok',
                  });
                });
            }
          }}
        />
        <WhiteButton
          label='Weiter shoppen'
          action={() => {
            history.push('/store');
          }}
        />
      </div>
      <Header backEnabled />
    </div>
  );
};

export default inject('shopStore', 'userStore')(observer(ShoppingCart));
