import React, { Component, useEffect } from "react";
import "./OrderHistory.css";
import Header from "../../../components/header/Header";
import { TitleHeaderGreen } from "../../../components/title-header-green/TitleHeaderGreen";
import { inject, observer } from "mobx-react";
import OrderHistoryCard from "./OrderHistoryCard/OrderHistoryCard";
import ShopStore from "../../../mobx/ShopStore";

declare var grecaptcha: any;

interface OrderHistoryProps {
  shopStore?: ShopStore;
}

const OrderHistory = ({ shopStore }: OrderHistoryProps) => {
  useEffect(() => {
    shopStore!.loadOrders();
    

    grecaptcha.ready(function () {
      grecaptcha.execute("6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk", {
        action: "OrderHistory_screen",
      });
    });
    return () => {};
  }, []);

  function renderOrderHistoryCards() {
    if (!shopStore!.myOrders) {
      return <div></div>;
    }
    if(shopStore!.myOrders.length === 0) {
      return <TitleHeaderGreen title="" subtitle="Es wurden keine Bestellungen für dich gefunden."/>
    }
    return shopStore!.myOrders.map((item, key) => {
      return <OrderHistoryCard order={item} key={key} />;
    });
  }

  return (
    <div className="order-history-container">
      <TitleHeaderGreen
        title="Bestellungen"
        subtitle="Hier findest du alle deine Bestellungen"
      />
      <div className="order-history-body">{renderOrderHistoryCards()}</div>
      <Header shoppingCartEnabled backEnabled></Header>
    </div>
  );
};

export default inject("shopStore")(observer(OrderHistory));
