import React, { Component, useState } from 'react';
import Checkbox from 'rc-checkbox';
import './EmailRegistration.css';

import TextInput from '../../components/TextInput/TextInput';
import Loading from '../../components/loading/Loading';
import Header from '../../components/header/Header';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import UserStore from '../../mobx/UserStore';
import { inject, observer } from 'mobx-react';
import Container from '../../components/Container/Container';
import WhiteButton from '../../components/White-Button/White-Button';

interface EmailRegistrationProps {
  userStore?: UserStore;
}

const EmailRegistration = ({ userStore }: EmailRegistrationProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [agbAccepted, setAgbAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  const [loading, setLoading] = useState(false);

  const verifyInputAndRegister = async () => {
    if (email === '') {
      Swal.fire({
        text: 'Bitte gib deine Email-Adresse ein!',
        type: 'error',
        confirmButtonColor: '#ffd42e',
        confirmButtonText: 'Ok',
      });
      return;
    }
    if (password === '') {
      Swal.fire({
        text: 'Bitte gib ein Passwort ein!',
        type: 'error',
        confirmButtonColor: '#ffd42e',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (password !== passwordRepeat) {
      Swal.fire({
        text: 'Die Passwörter stimmen nicht überein.',
        type: 'error',
        confirmButtonColor: '#ffd42e',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (agbAccepted === false) {
      Swal.fire({
        text: 'Bitte akzeptiere unsere AGB!',
        type: 'error',
        confirmButtonColor: '#ffd42e',
        confirmButtonText: 'Ok',
      });
      return;
    }

      if (privacyAccepted === false) {
          Swal.fire({
              text: 'Bitte akzeptiere unsere Datenschutzbestimmungen!',
              type: 'error',
              confirmButtonColor: '#ffd42e',
              confirmButtonText: 'Ok',
          });
          return;
      }

    setLoading(true);

    if (userStore) {
      setAgbAccepted(false);
      await userStore.register(email, password);
      setLoading(false);
    }
  };

  const renderMask = () => {
    return (
      <div className='register-container-form'>
        <TitleHeaderGreen title='Registrieren' subtitle='' />
        <TextInput
          placeholder='E-Mail'
          type='email'
          value={email}
          onChange={(event: any) => {
            setEmail(event.target.value);
          }}
        ></TextInput>
        <TextInput
          type='password'
          placeholder='Passwort'
          value={password}
          onChange={(event: any) => {
            setPassword(event.target.value);
          }}
        ></TextInput>
        <TextInput
          type='password'
          placeholder='Passwort wiederholen'
          value={passwordRepeat}
          onChange={(event: any) => {
            setPasswordRepeat(event.target.value);
          }}
        ></TextInput>
        <div className='register-agb'>
          <label>
            <Checkbox
              name='agb-checkbox'
              onChange={(e: any) => {
                setAgbAccepted(e.target.checked);
              }}
            />
            Ich stimme den <a href="/agb">Geschäftsbedingungen</a> zu.
          </label>
        </div>
          <div className='register-agb mb-20'>
              <label>
                  <Checkbox
                      name='privacy-checkbox'
                      onChange={(e: any) => {
                          setPrivacyAccepted(e.target.checked);
                      }}
                  />
                  Ich akzeptiere die <a href="/privacy">Datenschutzrichtlinien</a> und erlaube die Verarbeitung meiner persönlichen Daten.
              </label>
          </div>
        <WhiteButton
          action={() => {
            verifyInputAndRegister();
          }}
          label='Registrieren'
        />
        <Header backEnabled />
      </div>
    );
  };

  return (
    <Container>
      <div className='register-container'>
        <div className='register-content'>
          {loading && <Loading />}
          {!loading && renderMask()}
        </div>
      </div>
    </Container>
  );
};

export default inject('userStore', 'eventStore')(observer(EmailRegistration));
