import { observable, computed, action } from "mobx";
import Event from "../models/Event";
import StrapiHelper from "../services/StrapiHelper.service";
import jimp from "jimp";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default class CustomTrikotStore {
  @observable
  image: string = "";

  @observable
  cutOutImage: string = "";

  @observable
  loading: boolean = false;

  @action
  setImage(image: string) {
    this.image = image;
  }

  @action
  stopLoading() {
    this.loading = false;
  }

  @action
  startLoading() {
    this.loading = true;
  }

  @action
  clearCutOutImage() {
    this.cutOutImage = "";
  }

  @action
  async setCutOutImage(image: string) {
    this.startLoading();
    try {
      const response = await StrapiHelper.removeBackground(image);
      if (response && response.status === 200) {
        this.image = image;
        this.cutOutImage = response.data;
      }
    } catch (err) {
      Swal.fire({
        text:
          "Das Bild konnte nicht ausgeschnitten werden. Bitte probier es mit einem anderne Bild erneut.",
        type: "error",
        confirmButtonColor: "#ffd42e",
        confirmButtonText: "Ok",
      });
    }
  }
}
