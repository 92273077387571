import React, { useEffect } from "react";
import { Route, useHistory } from "react-router";

const PrivateRoute = ({ path, component }: any) => {
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("user") || !localStorage.getItem("mohren_token")) {
      localStorage.removeItem("user");
      localStorage.removeItem("mohren_token");
      history.push("/");
    }
    return () => {};
  }, []);

  return (
    <div className="parent">
      <div className="container">
        <Route path={path} component={component} />
      </div>
    </div>
  );
};

export default PrivateRoute;
