import React, { Component } from "react";
import "./EventCard.css";
import ImageWithSkeleton from "../../../components/ImageWithSkeleton/ImageWithSkeleton";
import Event from "../../../models/Event";
import EventStore from "../../../mobx/EventStore";
import { observer, inject } from "mobx-react";
import { useHistory } from "react-router-dom";
import placeholder from "../../../assets/Ansicht_Trikot_Standard.png";

interface EventCardProps {
  item?: Event;
  // push: any,
  imageFullscreen: boolean;
  style?: any;
  format?: string;
  hideLocation?: boolean;
  cardColor?: string;
  eventStore?: EventStore;
}

const EventCard = ({
  item,
  imageFullscreen,
  style,
  format,
  hideLocation,
  cardColor,
  eventStore,
}: EventCardProps) => {
  const history = useHistory();

  const generateImageUrl = (image: any) => {
    if (image && image.previewImage) {
      return image.previewImage.url;
    }
    return "";
  };
  return (
    <div
      className="event-card"
      style={style}
      onClick={() => {
        if (item && eventStore) {
          eventStore.setCurrentEvent(item);
          history.push("/eventdetails");
        }
      }}
    >
      {item && (
        <div
          className="event-card-small"
          style={{ backgroundColor: cardColor }}
        >
          {imageFullscreen && item.previewImage && item.previewImage.url && (
            <ImageWithSkeleton
              image={generateImageUrl(item)}
              imageClassName="event-card-fullscree-image"
              width="100%"
              height="160px"
              borderRadius="5px"
            />
          )}
          {!imageFullscreen && item.previewImage && (
            <ImageWithSkeleton
              image={item.previewImage.url}
              imageClassName="event-card-image"
              width="100%"
              height="160px"
              borderRadius="5px"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default inject("eventStore")(observer(EventCard));

// @inject('eventStore')
// @observer
// class EventCard extends Component<EventCardProps, {}> {
//     // render() {
//     //     return <div></div>
//     // }
//     render() {
//         return (
//             <div className="event-card" style={style} onClick={() => {
//                 if (item && eventStore) {
//                     eventStore.setCurrentEvent(item);
//                     push("/eventdetails/" + item.id, item);
//                 }
//             }}>
//                 {item && <div className="event-card-small" style={{ backgroundColor: cardColor }}>
//                     {imageFullscreen && item.PreviewImage && item.PreviewImage.url &&
//                         <ImageWithSkeleton
//                             image={item.PreviewImage.url}
//                             imageClassName="event-card-fullscree-image"
//                             width="100%"
//                             height="160px"
//                             borderRadius="5px"
//                         />
//                     }
//                     {!imageFullscreen && item.PreviewImage &&
//                         <ImageWithSkeleton
//                             image={item.PreviewImage.url}
//                             imageClassName="event-card-image"
//                             width="100%"
//                             height="160px"
//                             borderRadius="5px"
//                         />
//                     }
//                 </div>}
//             </div>
//         );
//     }
// }

// // const mapDispatchToProps = (dispatch: any) => {
// //     return {
// //         push(route: string) {
// //             dispatch(push(route));
// //         }
// //     };
// // };

// export default  EventCard;
