import React, { Component } from "react";
import "./TextArea.scss";
import Autocomplete from "react-google-autocomplete";

interface TextInputProps {
  placeholder: string;
  onChange?: any;
  value?: any;
  type?: string;
  autoComplete?: string;
  inValid?: boolean;
  onEnter?: any;
  style?: any;
  isGoogleInput?: boolean;
  onGooglePlaceSelected?: (place: any) => void;
}

interface TextInputState {
  opened: boolean;
}

class TextArea extends Component<TextInputProps, TextInputState> {
  private _input: any;

  constructor(props: any) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  onEnterPressed(event: any) {
    if (event.key == "Enter") {
      if (this.props.onEnter) {
        this.props.onEnter();
      }
    }
  }

  handleAutoFill(e) {}

  renderGoogleInput = () => {
    return (
      //@ts-ignore
      <Autocomplete
        ref={this._input}
        className={"textarea-gray"}
        types={["address"]}
        fields={[
          "address_components",
          "geometry.location",
          "place_id",
          "formatted_address",
        ]}
        componentRestrictions={{ country: "at" }}
        value={this.props.value}
        placeholder={""}
        onChange={(event) => this.props.onChange(event)}
        onPlaceSelected={(place: any) =>
          this.props.onGooglePlaceSelected &&
          this.props.onGooglePlaceSelected(place)
        }
        onFocus={() => {
          this.setState({ opened: true });
        }}
        onBlur={() => {
          this.setState({ opened: false });
        }}
      />
    );
  };

  render() {
    return (
      <div
        className="field-container"
        style={this.props.style}
        onKeyPress={(event) => {
          this.onEnterPressed(event);
        }}
        onClick={() => this.setState({ opened: true })}
        onBlur={() => this.setState({ opened: false })}
      >
        <div
          className={
            this.state.opened || this.props.value.length > 0
              ? "field-group field-group-active"
              : "field-group"
          }
        >
          <label
            className={
              this.state.opened || this.props.value.length > 0
                ? "field-active"
                : "field-inactive"
            }
          >
            <div
              className={
                this.state.opened || !this.props.inValid
                  ? "placeholdeText"
                  : "placeholdeText-error"
              }
            >
              {this.props.placeholder}
            </div>
          </label>
          <textarea
            ref={(ref) => (this._input = ref)}
            className={this.props.inValid ? "textarea-error" : "textarea-gray"}
            value={this.props.value}
            onFocus={() => {
              this.setState({ opened: true });
            }}
            onBlur={() => {
              this.setState({ opened: false });
            }}
            onChange={(event) => this.props.onChange(event)}
            onAnimationStart={this.handleAutoFill}
            spellCheck={false}
          />
        </div>
      </div>
    );
  }
}

export default TextArea;
