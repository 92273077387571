import React, { Component, useEffect, useState } from 'react';
import './ProductDetail.css';
import { inject, observer } from 'mobx-react';
import ShopStore from '../../../mobx/ShopStore';
import Header from '../../../components/header/Header';
import DropDownSize from '../../../components/DropDownSize/DropDownSize';
import Product from '../../../models/Product';
import PaymentIcon from 'react-payment-icons';
import { useHistory, useParams } from 'react-router-dom';
import CustomTrikotStore from '../../../mobx/CustomTrikotStore';
import WhiteButton from '../../../components/White-Button/White-Button';
import Loading from '../../../components/loading/Loading';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

interface ProductDetailProps {
  shopStore?: ShopStore;
  customTrikotStore?: CustomTrikotStore;
}

const ProductDetail = ({
  shopStore,
  customTrikotStore,
}: ProductDetailProps) => {
  const history = useHistory();
  //@ts-ignore
  const { id } = useParams();
  const [amountOptions, setAmountOptions]: any = useState([]);
  const [options, setOptions] = useState([]);
  const [productNotAvailable, setProductNotAvailable] = useState(false);
  const [selectedOption, setSelectedOption]: any = useState();
  const [selectedAmountOption, setSelectedAmountOption] = useState({
    label: '0',
    value: 0,
  });
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const split = window.location.pathname.split('/');
    if (split && split[2]) {
      shopStore!.loadCurrentShopItem(parseInt(split[2]));
    }
  }, [id]);

  //set options
  useEffect(() => {
    if (shopStore!.currentShopItem) {
      let tmp = [];
      for (let i of shopStore!.currentShopItem.productparts) {
        tmp.push({
          //@ts-ignore
          value: i.id,
          //@ts-ignore
          label: i.option,
        });
      }
      if (tmp.length === 0) {
        setProductNotAvailable(true);
      }

      setSelectedOption(tmp[0]);
      setOptions(tmp);
    }
  }, [shopStore!.currentShopItem]);

  useEffect(() => {
    if (shopStore!.currentShopItem && selectedOption) {
      for (let op of shopStore!.currentShopItem!.productparts) {
        if (op.id === selectedOption.value) {
          const tmp = generateAmount(op);
          if (tmp.length === 0) {
            setProductNotAvailable(true);
          }
          setAmountOptions(tmp);
          setSelectedAmountOption(tmp[0]);
        }
      }
      //  setAmountOptions(generateAmount());
    }
  }, [selectedOption]);

  const generateAmount = (item: any) => {
    let op: any[] = [];
    const maxAmount = item.stock > 10 ? 10 : item.stock;
    for (let i = 1; i < maxAmount; i++) {
      op.push({ value: i, label: i });
    }
    return op;
  };

  function renderText(text: string) {
    if (!text) {
      return <></>;
    }

    return text.split('\n').map((item, key) => {
      return (
        <div className='product-detail-text' key={key}>
          {item}
        </div>
      );
    });
  }

  function renderContent(shopItem: Product) {
    return (
      <div className='product-detail'>
        <div className='product-detail-container'>
          <div style={{ marginTop: 20 }}>
            <div className='product-detail-title'>{shopItem.name}</div>
            <div className='product-detail-description'>
              <div className='product-detail-text'>
                {renderText(shopItem.description)}
              </div>
              <div className='seperator'></div>
              <PaymentIcon
                id='visa'
                style={{ width: 40, margin: 15, marginLeft: 0 }}
                className='payment-icon'
              />
              <PaymentIcon
                id='mastercard'
                style={{ width: 40, margin: 15, marginLeft: 0 }}
                className='payment-icon'
              />
              <PaymentIcon
                id='maestro'
                style={{ width: 40, margin: 15, marginLeft: 0 }}
                className='payment-icon'
              />
              <div className='product-detail-text'>
                Preis:{' '}
                {shopItem.price && shopItem.price.toFixed(2).replace('.', ',')}€
              </div>
            </div>
            {options.length > 1 && (
              <>
                <div
                  className='product-detail-size-title'
                  style={{ marginTop: 10 }}
                >
                  Option
                </div>
                <DropDownSize
                  placeholder='Option ...'
                  options={options}
                  selectedOption={selectedOption}
                  selectItem={(option: any) => {
                    setSelectedOption(option);
                  }}
                />
              </>
            )}
            {!productNotAvailable && (
              <>
                <div
                  className='product-detail-size-title'
                  style={{ marginTop: 10 }}
                >
                  Menge
                </div>
                <DropDownSize
                  placeholder='Menge ...'
                  options={amountOptions}
                  selectedOption={selectedAmountOption}
                  selectItem={(option: any) => {
                    setSelectedAmountOption(option);
                  }}
                />
                <div style={{ marginBottom: 22 }}> </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  function isButtonPressable() {
    return selectedAmountOption && selectedAmountOption.value > 0;
  }

  function onDefaultContinue(shopItem: any) {
    if (shopStore!.currentShopItem) {
      shopStore!.addItemToShoppingCart({
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        product: shopItem,
        amount: selectedAmountOption.value,
        productPart: selectedOption.value,
        option: selectedOption.label,
      });
      shopStore!.setCurrentShopItem(undefined);
      history.push('/shoppingcart');
    }
  }

  function onCustomContinue() {
    if (shopStore!.currentShopItem && selectedOption) {
      shopStore!.setCurrentOrderPart({
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        product: shopStore!.currentShopItem,
        amount: selectedAmountOption.value,
        productPart: selectedOption.value,
        option: selectedOption.label,
      });

      const id = shopStore!.currentShopItem.id;
      shopStore!.setCurrentShopItem(undefined);

      history.push('/customproduct/' + id);
    }
  }

  function _renderDots() {
    return (shopStore!.currentShopItem as Product).images.map((item, key) => {
      return (
        <div
          key={key}
          className={
            key == currentSlide
              ? 'product-detail-footer-dots-active'
              : 'product-detail-footer-dots'
          }
        ></div>
      );
    });
  }
  if (!shopStore!.currentShopItem) {
    return <div />;
  }

  const sliderPictureMode =
    shopStore!.currentShopItem && shopStore!.currentShopItem.images.length > 1;

  const getImage = () => {
    if (shopStore!.currentShopItem) {
      if (shopStore!.currentShopItem.images[0]) {
        return shopStore!.currentShopItem.images[0].url;
      }
      if (shopStore!.currentShopItem.previewImage) {
        return shopStore!.currentShopItem.previewImage.url;
      }
    }
    return '';
  };

  return shopStore!.loading ? (
    <Loading />
  ) : (
    <div style={{ overflowY: 'auto', marginBottom: 80 }}>
      <div
        className='awesome-slider-container'
        style={{
          backgroundColor: '#007234',
        }}
      >
        {sliderPictureMode && (
          <Swiper loop={sliderPictureMode}>
            {shopStore!.currentShopItem.images.map((item, key) => {
              return (
                <SwiperSlide key={key}>
                  <img
                    style={{
                      objectFit: 'contain',
                      maxWidth: '100%',
                      width: '100%',
                    }}
                    src={item.url}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        {!sliderPictureMode && (
          <img src={getImage()} className='product-detail-slider' />
        )}
        {sliderPictureMode && (
          <div className='product-detail-footer'>{_renderDots()}</div>
        )}
      </div>
      <div className='product-detail-fullheight'>
        {renderContent(shopStore!.currentShopItem)}
        <div className='m-20 mt-0' style={{ marginBottom: 50 }}>
          {!productNotAvailable ? (
            <>
              {' '}
              {!shopStore!.currentShopItem.isSpecialProduct && (
                <WhiteButton
                  disabled={!isButtonPressable()}
                  label='In den Warenkorb'
                  action={() => {
                    if (isButtonPressable()) {
                      onDefaultContinue(shopStore!.currentShopItem);
                    }
                  }}
                />
              )}
              {shopStore!.currentShopItem.isSpecialProduct && (
                <WhiteButton
                  label='Personalisieren'
                  disabled={!isButtonPressable()}
                  action={() => {
                    if (isButtonPressable()) {
                      customTrikotStore!.clearCutOutImage();
                      customTrikotStore!.image = '';
                      onCustomContinue();
                    }
                  }}
                />
              )}
            </>
          ) : (
            <div
              className='product-detail-text'
              style={{ textAlign: 'center', marginTop: '20px' }}
            >
              Produkt derzeit nicht verfügbar
            </div>
          )}
        </div>
        <Header backEnabled shoppingCartEnabled />
      </div>
    </div>
  );
};

export default inject(
  'shopStore',
  'customTrikotStore'
)(observer(ProductDetail));
