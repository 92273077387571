import React, { useEffect, useState } from 'react';
import './HomePage.css';

import { inject, observer } from 'mobx-react';

import Header from '../../components/header/Header';

import { useHistory } from 'react-router-dom';
import UserStore from '../../mobx/UserStore';
import { isIOS } from 'react-device-detect';

import brausilvester from '../../assets/herbst24/Startseite_App_Brausilvester.jpg';
import EventStore from '../../mobx/EventStore';
import InfoModal from '../../components/InfoModal/InfoModal';

declare var grecaptcha: any;

interface HomeProps {
  userStore: UserStore;
  eventStore: EventStore;
}

const HomePage = ({ userStore, eventStore }: HomeProps) => {
  const history = useHistory();

  useEffect(() => {
    userStore.getCurrentUser();

    if (userStore.currentUser) {
      if (!userStore.currentUser.username) {
        history.push('/username');
      }
    }

    grecaptcha.ready(function () {
      grecaptcha.execute('6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk', {
        action: 'home_screen',
      });
    });
  }, []);

  if (!userStore.currentUser) {
    return <></>;
  }
  return (
    <div>
      <div className='fullscreen'>
        <div
          onClick={() => {
            eventStore.setBrauSilvesterEvent();
            history.push('/eventdetails');
          }}
        >
          <img
            alt='brausilvester'
            src={brausilvester}
            className='fullscreen-image'
            fetchPriority='high'
          />
        </div>

        <InfoModal
          title='Die Gewinner von unserem Mohrenbräu Summar Gwinn stehen fest! 🤩'
          htmlText={
            <span>
              Die Gewinner wurden per E-Mail benachrichtigt - also gleich Postfach
              checken! <br />
              Zur Sicherheit auch Spam checken. <br />
              <br />
              Leider können keine Codes mehr eingegeben werden. <br />
              <br />
              Danke fürs Mitspielen - wir freuen uns schon aufs nächste Jahr!
            </span>
          }
          buttonText='Okay, cool'
          hide={!userStore.showHomeModal}
          onClose={() => userStore.homeModalClosed()}
        />

        <Header cameraEnabled={isIOS} />
      </div>
    </div>
  );
};

export default inject('userStore', 'eventStore')(observer(HomePage));
