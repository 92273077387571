import React, { Component, useEffect } from "react";
import "./OrderDone.css";
import { inject } from "mobx-react";
import ShopStore from "../../../mobx/ShopStore";

import Header from "../../../components/header/Header";
import YellowButton from "../../../components/Yellow-Button/Yellow-Button";
import { TitleHeaderGreen } from "../../../components/title-header-green/TitleHeaderGreen";
import { useHistory } from "react-router";

interface OrderDoneProps {
  shopStore?: ShopStore;
  navigateTo: Function;
}

const OrderDone = ({ shopStore }: OrderDoneProps) => {
  const history = useHistory();

  useEffect(() => {
    shopStore!.setCurrentOrder(undefined);
    shopStore!.setCurrentClientSecret("");
    shopStore!.clearShoppingCard();
    shopStore!.hidePaymentDialog();
  }, []);

  const renderContent = () => {
    return (
      <div className="order-done">
        <TitleHeaderGreen
          title="Vielen Dank für deine Bestellung."
          subtitle="Du erhältst in Kürze eine E-Mail mit allen wichtigen Informationen."
        />
        <div className="order-done-text">
          Bestellnummer: {shopStore!.orderNumber}
        </div>
        <div style={{ margin: 25 }}>
          <YellowButton
            label="Fertig"
            onClick={() => {
              history.push("/home");
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="product-detail-fullheight">
      {renderContent()}
      <Header />
    </div>
  );
};

export default inject("shopStore")(OrderDone);
