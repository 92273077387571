import { observable, computed, action } from 'mobx';
import StrapiHelper from '../services/StrapiHelper.service';
import Swal from 'sweetalert2';

export default class UserStore {
  @observable
  currentUser: any;

  @observable
  showHomeModal: boolean = true;

  navigation: any;

  @observable
  loading: any = false;

  @observable
  error: any = '';

  @observable
  leaderBoard: any = [];

  @observable
  currentLeaderBoardEntry: any = {};

  @observable
  currentLeaderBoardRank: any;

  @observable
  isCurrentEntryOutOfRange: boolean = false;

  constructor() {
    if (window.localStorage.getItem('user')) {
      var localUser = window.localStorage.getItem('user')!;
      this.currentUser = JSON.parse(localUser);
    }
  }

  @action
  async login(email: string, password) {
    try {
      const response = await StrapiHelper.emailLogin(email, password);
      if (response.status === 200) {
        const { jwt, user } = response.data;
        let userWithoutCodes = { ...user };
        delete userWithoutCodes.codes; // Remove the codes field

        localStorage.setItem('mohren_token', jwt);
        localStorage.setItem('user', JSON.stringify(userWithoutCodes));
        this.currentUser = userWithoutCodes;
        return true;
      }
    } catch (err) {
      const errAny = err as any;

      if (
        errAny.response &&
        errAny.response.data &&
        errAny.response.data.message &&
        errAny.response.data.message[0] &&
        errAny.response.data.message[0].messages &&
        errAny.response.data.message[0].messages[0] &&
        errAny.response.data.message[0].messages[0].id ===
          'Auth.form.error.confirmed'
      ) {
        Swal.fire({
          text: 'Bitte bestätige deine Email-Adresse',
          type: 'error',
          confirmButtonColor: '#ffd42e',
          confirmButtonText: 'Ok',
        });

        return false;
      }
      console.log(err);
      Swal.fire({
        text: 'Deine Email oder dein Passwort sind inkorrekt!',
        type: 'error',
        confirmButtonColor: '#ffd42e',
        confirmButtonText: 'Ok',
      });
      return false;
    }
  }

  @action
  async loginWithFacebook(token: string) {
    try {
      const response = await StrapiHelper.loginWithFB(token);

      if (response.status === 200) {
        const { jwt, user } = response.data;
        let userWithoutCodes = { ...user };
        delete userWithoutCodes.codes; // Remove the codes field

        localStorage.setItem('mohren_token', jwt);
        localStorage.setItem('user', JSON.stringify(userWithoutCodes));
        this.currentUser = userWithoutCodes;
        return true;
      }
    } catch (err) {
      console.log(err);

      return false;
    }
  }

  @action
  async loginWithApple(token: string) {
    try {
      const response = await StrapiHelper.loginWithApple(token);

      if (response.status === 200) {
        const { jwt, user } = response.data;

        let userWithoutCodes = { ...user };
        delete userWithoutCodes.codes; // Remove the codes field

        localStorage.setItem('mohren_token', jwt);
        localStorage.setItem('user', JSON.stringify(userWithoutCodes));
        this.currentUser = userWithoutCodes;
        return true;
      }
    } catch (err) {
      console.log(err);

      return false;
    }
  }

  @action
  async register(email, password) {
    try {
      const response = await StrapiHelper.emailRegister(email, password);
      if (response.status === 200) {
        if (this.navigation) {
          this.navigation.push('emailregistrationdone');
        }
      }
    } catch (err) {
      let errAny = err as any;
      if (
        errAny.response &&
        errAny.response.data &&
        errAny.response.data.message &&
        errAny.response.data.message[0] &&
        errAny.response.data.message[0].messages &&
        errAny.response.data.message[0].messages[0] &&
        errAny.response.data.message[0].messages[0].id ===
          'Auth.form.error.email.taken'
      ) {
        Swal.fire({
          text: 'Diese Email wird schon verwendet!',
          type: 'error',
          confirmButtonColor: '#ffd42e',
          confirmButtonText: 'Ok',
        });

        return false;
      }
      console.log(err);
      Swal.fire({
        text: 'Deine Email oder dein Passwort sind inkorrekt!',
        type: 'error',
        confirmButtonColor: '#ffd42e',
        confirmButtonText: 'Ok',
      });
      return false;
    }
  }

  @action
  async setTutorialCompleted(completed: boolean) {
    let userObj = this.currentUser;
    userObj['aktion24TutorialCompleted'] = completed;
    userObj['tutorialCompleted'] = completed;
    userObj['tippGameTutorialCompleted'] = completed;
    this.updateUser(userObj);
  }

  @action setTippGameTutorialCompleted(completed: boolean) {
    let userObj = this.currentUser;
    userObj['tippGameTutorialCompleted'] = completed;
    this.updateUser(userObj);
  }

  @action setAktion24TutorialCompleted(completed: boolean) {
    let userObj = this.currentUser;
    userObj['aktion24TutorialCompleted'] = completed;
    this.updateUser(userObj);
  }

  @action
  setAgbChecked(checked: boolean) {
    let userObj = this.currentUser;
    userObj['agbChecked'] = checked;

    this.currentUser = userObj;
  }

  @action
  setUsername(username: any) {
    let userObj = this.currentUser;
    userObj['username'] = username;
    this.currentUser = userObj;
  }

  @action
  stopLogin() {
    this.loading = false;
  }

  @action
  startLogin() {
    this.loading = true;
  }

  @action
  homeModalClosed() {
    this.showHomeModal = false;
  }

  @action
  setLeaderBoard(leaderboard: any) {
    this.leaderBoard = leaderboard;
    var currentLeaderBoardEntry = leaderboard.filter(
      (item) => item.id === this.currentUser.id
    )[0];

    var currentLeaderBoardRank =
      leaderboard.indexOf(currentLeaderBoardEntry) + 1;

    this.currentLeaderBoardEntry = currentLeaderBoardEntry;
    this.currentLeaderBoardRank = currentLeaderBoardRank;
  }

  @action
  async setActivePlayer(player: any) {
    let userObj = this.currentUser;
    userObj['player'] = player;

    await this.updateUser(userObj);
  }

  @action
  updateUserLocal(user) {
    let jsonUser = JSON.stringify(user);

    if (jsonUser) {
      localStorage.removeItem('user');
      localStorage.setItem('user', jsonUser);
    }
  }

  @action
  async getCurrentUser() {
    try {
      let response = await StrapiHelper.getCurrentUser();
      let userWithoutCodes = { ...response.data };
      delete userWithoutCodes.codes; // Remove the codes field

      this.currentUser = userWithoutCodes;
      this.updateUserLocal(userWithoutCodes);
    } catch (err) {}
  }

  @action
  async updateUser(user) {
    const tmpUser = JSON.parse(JSON.stringify(user));
    for (let key in tmpUser) {
      if (
        tmpUser[key] == null ||
        key === 'codes' ||
        key === 'logs' ||
        key === 'points' ||
        key === 'username' ||
        key === 'totalPoints' ||
        key === 'tickets'
      ) {
        delete tmpUser[key];
      }
    }

    try {
      const response = await StrapiHelper.updateUser(tmpUser);
      if (response.status == 200) {
        let userWithoutCodes = { ...user };
        delete userWithoutCodes.codes; // Remove the codes field

        this.updateUserLocal(userWithoutCodes);
        this.currentUser = userWithoutCodes;

        this.loading = false;
      } else {
        this.error = 'Error';
        this.loading = false;
      }
      return response;
    } catch (err) {
      console.log(err);
      Swal.fire({
        text: 'Speichern hat nicht funktioniert!',
        type: 'error',
        confirmButtonColor: '#ffd42e',
        confirmButtonText: 'Ok',
      });

      this.loading = false;
      return err;
    }
  }

  @action
  async updateUsername(user, userName?) {
    for (let key in user) {
      if (
        user[key] == null ||
        key === 'codes' ||
        key === 'logs' ||
        key === 'points' ||
        key === 'totalPoints' ||
        key === 'tickets'
      ) {
        delete user[key];
      }
    }

    // try {
    const response = await StrapiHelper.updateUser(user);
    if (response.status == 200) {
      let userWithoutCodes = { ...response.data };
      delete userWithoutCodes.codes; // Remove the codes field

      this.updateUserLocal(userWithoutCodes);
      this.currentUser = userWithoutCodes;
    }
    return response;
  }

  @action
  async updateUserImage(image) {
    let userObj = this.currentUser;
    userObj['image'] = image;
    await this.updateUser(userObj);
  }

  @action
  async addPoints(points: any) {
    let userObj = this.currentUser;
    userObj['points'] = points;
    await this.updateUserLocal(userObj);
  }

  @action
  async addTickets(tickets: any) {
    let userObj = this.currentUser;
    userObj['tickets'] = tickets;
    await this.updateUserLocal(userObj);
  }

  @action
  async setWatchedStories(stories) {
    let userObj = this.currentUser;
    userObj['watchedStories'] = stories;

    for (let key in userObj) {
      if (
        userObj[key] == null ||
        key === 'codes' ||
        key === 'logs' ||
        key === 'points' ||
        key === 'totalPoints' ||
        key === 'tickets'
      ) {
        delete userObj[key];
      }
    }

    // try {
    const response = await StrapiHelper.updateUser(userObj);
    if (response.status == 200) {
      let userWithoutCodes = { ...response.data };
      delete userWithoutCodes.codes; // Remove the codes field

      this.updateUserLocal(userWithoutCodes);
      this.currentUser = userWithoutCodes;
    }
    return response;
  }

  @action
  logout() {
    localStorage.removeItem('ShoppingCart');
    localStorage.removeItem('watched_stories');
    localStorage.removeItem('user');
    localStorage.removeItem('mohren_token');
  }

  @action
  forgotPassword(email: string) {
    this.startLogin();
    return StrapiHelper.forgotMyPassword(email);
  }

  @action
  setOutOfRangeLeaderBoardEntry(entry: any) {
    if (entry) {
      this.isCurrentEntryOutOfRange = true;

      this.currentLeaderBoardEntry = entry;
      this.currentLeaderBoardRank = entry.rank;
    } else {
      this.isCurrentEntryOutOfRange = false;
    }
  }

  @action
  async getLeaderBoard(filter: string) {
    this.startLogin();
    this.setOutOfRangeLeaderBoardEntry(null);
    await StrapiHelper.getLeaderBoardByFilter(filter)
      .then((result) => {
        this.setLeaderBoard(result.data.leaderboard.slice(0, 100));

        this.setOutOfRangeLeaderBoardEntry(result.data.userOutOfRange);
        this.stopLogin();
      })
      .catch((err) => {});
  }

  @action
  async deleteAccount() {
    await StrapiHelper.deleteUser()
      .then((result) => {
        this.logout();
      })
      .catch((err) => {});
  }

  @action
  async resetPassword(code, password, repeatedPassword) {
    try {
      const response = await StrapiHelper.resetPassword(
        code,
        password,
        repeatedPassword
      );
      if (response && response.status === 200) {
        const { jwt, user } = response.data;

        let userWithoutCodes = { ...user };
        delete userWithoutCodes.codes; // Remove the codes field

        localStorage.setItem('mohren_token', jwt);
        localStorage.setItem('user', JSON.stringify(userWithoutCodes));
        this.currentUser = userWithoutCodes;
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  }
}
