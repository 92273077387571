import React, { Component, useEffect } from "react";
import "./Datenschutz.css";
import Header from "../../components/header/Header";
import { TitleHeaderGreen } from "../../components/title-header-green/TitleHeaderGreen";
import TextSlideDown from "../../components/TextSlideDown/TextSlideDown";
import dateschutz from "./datenschutz.json";

const Datenschutz = () => {
  useEffect(() => {
    
    return () => {};
  }, []);

  function formatText(text) {
    return text.split("\n").map((item, i) => {
      return <p key={i}>{item}</p>;
    });
  }

  return (
    <div
      style={{
        paddingTop: 70,
        backgroundColor: "rgb(0, 117, 64)",
        paddingBottom: 56,
      }}
    >
      <TitleHeaderGreen title="Datenschutz" subtitle="" />
      <div className="faq-title">Datenschutzerklärung nach der DSGVO</div>
      <div className="faq-subtitle">
        {formatText(
          "Wir verarbeiten Ihre personenbezogenen Daten ausschließlich im Rahmen der Bestimmungen der EU Datenschutzgrundverordnung (DSGVO), des österreichischen Datenschutzgesetzes (DSG) sowie dem Telekommunikationsgesetz (TKG).\nHinweis: Zur leichteren Lesbarkeit wird idR der Begriff „Daten“ verwendet, wenngleich personenbezogene Daten gemeint sind. Gesetzesbestimmungen ohne Angabe beziehen sich ausschließlich auf jene der DSGVO, soweit nicht anders angegeben.\nDiese Datenschutzerklärung informiert Sie über die Art, den Umfang und die Zwecke der Erhebung und Verwendung Ihrer personenbezogenen Daten durch uns im Zusammenhang mit Ihrem Besuch und Ihrer Nutzung unserer Website www.mohrenbrauerei.at."
        )}
      </div>

      {dateschutz.map((item: any, key) => {
        return (
          <div key={key} style={{ padding: 20 }}>
            <div className="faq-question-title">{item.title}</div>
            {item.questions ? (
              item.questions.map((item, key) => {
                return (
                  <div className="textslide-seperator">
                    <TextSlideDown item={item} key={key} />
                  </div>
                );
              })
            ) : (
              <div className="textslide-text">
                {formatText(item.description)}
              </div>
            )}
          </div>
        );
      })}

      <Header backEnabled backUrl={"/profil"}></Header>
    </div>
  );
};

export default Datenschutz;
