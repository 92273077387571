import React, { Component, useEffect } from "react";
import "./Username.css";
import Loading from "../../../components/loading/Loading";
import { TitleHeaderGreen } from "../../../components/title-header-green/TitleHeaderGreen";
import YellowButton from "../../../components/Yellow-Button/Yellow-Button";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import TextInput from "../../../components/TextInput/TextInput";
import Swal from "sweetalert2";

interface UsernamePageProps {
  userStore?: any;
}

const UsernamePage = ({ userStore }: UsernamePageProps) => {
  const history = useHistory();
  useEffect(() => {
    if (userStore.currentUser) {
      if (userStore.currentUser.username) {
        history.push("/home");
      }
    }
    return () => {};
  }, []);

  let username = "";
  if (userStore.currentUser.username) {
    username = userStore.currentUser.username;
  }

  return (
    <div className="content">
      <Loading />
      <div className="username-container">
        <TitleHeaderGreen
          title="Anzeigename"
          subtitle="Bitte wähle einen Username aus."
        />
        <div className="username-input-container">
          <TextInput
            placeholder="Username"
            type="text"
            value={username}
            onChange={(event) => {
              userStore.setUsername(event.target.value);
            }}
          ></TextInput>
        </div>
        {
          <div
            className={
              "username-btn-container " +
              (username.length <= 0 && "username-btn-container-disabled")
            }
          >
            <YellowButton
              label="Fertig"
              onClick={async () => {
                if (username.length > 0) {
                  userStore
                    .updateUsername(userStore.currentUser)
                    .then(() => history.push("/home"))
                    .catch((err) => {
                      if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message &&
                        err.response.data.message[0] &&
                        err.response.data.message[0].messages &&
                        err.response.data.message[0].messages[0] &&
                        err.response.data.message[0].messages[0].id ===
                          "Auth.form.error.username.taken"
                      ) {
                        Swal.fire({
                          text: "Dieser Username ist schon vergeben!",
                          type: "error",
                          confirmButtonColor: "#ffd42e",
                          confirmButtonText: "Ok",
                        });
                      } else {
                        Swal.fire({
                          text: "Username ist inkorrekt!",
                          type: "error",
                          confirmButtonColor: "#ffd42e",
                          confirmButtonText: "Ok",
                        });
                      }
                    });
                }
              }}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default inject("userStore")(observer(UsernamePage));
