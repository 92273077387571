import React, { useEffect } from "react";
import { TitleHeaderGreen } from "../../components/title-header-green/TitleHeaderGreen";
import impressumObj from "./impressum.json";
import Header from "../../components/header/Header";

const Impressum = () => {
  useEffect(() => {
    
    return () => {};
  }, []);

  function formatText(text) {
    return text.split("\n").map((item, i) => {
      if (item === "Programmierung: BoehlerBrothers") {
        return (
          <p>
            Programmierung:
            <br />
            <a href="https://www.boehlerbrothers.com/" target="_blank">
              BoehlerBrothers
            </a>
          </p>
        );

      }
      return <p key={i}>{item}</p>;
    });
  }

  return (
    <div
      style={{
        paddingTop: 70,
        backgroundColor: "rgb(0, 117, 64)",
        paddingBottom: 56,
      }}
    >
      <TitleHeaderGreen title="Impressum" subtitle="" />

      {impressumObj.map((item: any, key) => {
        return (
          <div key={key} style={{ padding: 20 }}>
            <div className="faq-question-title">{item.title}</div>
            {item.questions ? (
              item.questions.map((item, key) => {
                return <div className="textslide-seperator"></div>;
              })
            ) : (
              <div className="textslide-text">
                {formatText(item.description)}
              </div>
            )}
            <div className="textslide-seperator"></div>
          </div>
        );
      })}

      <Header backEnabled backUrl={"/profil"}></Header>
    </div>
  );
};

export default Impressum;
