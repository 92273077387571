import React, { Component, useEffect } from "react";
import "./Shop.css";
import Header from "../../components/header/Header";
import { TitleHeaderGreen } from "../../components/title-header-green/TitleHeaderGreen";
import { inject, observer } from "mobx-react";
import ShopCart from "../../components/shop-cart/ShopCart";

declare var grecaptcha: any;

interface ShopProps {
  shopStore?: any;
}

const Shop = ({ shopStore }: ShopProps) => {
  useEffect(() => {
    
    shopStore.loadProductGroups();
    shopStore.loadShopCardItems();
    grecaptcha.ready(function () {
      grecaptcha.execute("6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk", {
        action: "shop_screen",
      });
    });
    return () => {};
  }, []);

  function renderShopCards() {
    return shopStore.shopItems.map((product, index) => {
      return (
        <ShopCart
          item={product}
          key={index}
          style={{ marginBottom: index == 0 ? 20 : 0 }}
        />
      );
    });
  }
  return (
    <div className="shop-container">
      <TitleHeaderGreen
        title="s’Lädele"
        subtitle="Unser Mohren Spezial kommt jetzt bequem zu dir nach Hause."
      />
      <div className="shop-body">{renderShopCards()}</div>
      <Header shoppingCartEnabled></Header>
    </div>
  );
};

export default inject("shopStore")(observer(Shop));
