import React, { Component } from 'react'
import './Yellow-Button.css'
import ReactLoading from 'react-loading';

class YellowButton extends Component<{ label: string, onClick?: any, uppercase?: boolean, style?: any, disabled?: boolean, loading?:boolean }, {}> {
    componentDidMount() {
    }

    render() {
        if (this.props.disabled) {
            return (
                <button
                    className="gray-button"
                    style={{ textTransform: this.props.uppercase ? 'uppercase' : 'none', ... this.props.style }}
                >
                    {!this.props.loading && this.props.label}
                </button>
            )
        }
        if (this.props.onClick) {
            return (
                <button
                    className="yellow-button"
                    style={{ textTransform: this.props.uppercase ? 'uppercase' : 'none', ... this.props.style }}
                    onClick={() => { this.props.onClick() }}>
                   {!this.props.loading && this.props.label}
                   {this.props.loading &&    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><ReactLoading type={"balls"} color={'black'} height={30} width={30} /></div>
}
                </button>
            )
        } else {
            return (
                <button className="yellow-button" style={{}}>{this.props.label}</button>
            )
        }
    }
}

export default YellowButton; 