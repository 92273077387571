import React, { Component, useEffect } from "react";
import "./Teilnahmebedingung.css";
import Header from "../../components/header/Header";
import { TitleHeaderGreen } from "../../components/title-header-green/TitleHeaderGreen";
import TextSlideDown from "../../components/TextSlideDown/TextSlideDown";
import teilnahmebedinungen from "./teilnahmebedingung.json";

const Teilnahmebedingung = () => {
  useEffect(() => {

    return () => {};
  }, []);

  function formatText(text) {
    return text.split("\n").map((item, i) => {
      return <p key={i}>{item}</p>;
    });
  }

  return (
    <div
      style={{
        paddingTop: 70,
        backgroundColor: "rgb(0, 117, 64)",
        paddingBottom: 56,
      }}
    >
      <TitleHeaderGreen
        title={"NUTZUNGS- UND TEILNAHME-" + "\n" + "BEDINGUNGEN"}
        subtitle=""
      />
      {teilnahmebedinungen.map((item: any, key) => {
        return (
          <div key={key} style={{ padding: 20 }}>
            <div className="faq-question-title">{item.title}</div>
            {item.questions ? (
              item.questions.map((item, key) => {
                return (
                  <div className="textslide-seperator">
                    <TextSlideDown item={item} key={key} />
                  </div>
                );
              })
            ) : (
              <div className="textslide-text">
                {formatText(item.description)}
              </div>
            )}
          </div>
        );
      })}


      {<Header backEnabled backUrl={"/profil"}></Header>}
    </div>
  );
};

export default Teilnahmebedingung;
