import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import "./CheckoutForm.css";
import { inject } from "mobx-react";
import ShopStore from "../../../mobx/ShopStore";
import YellowButton from "../../../components/Yellow-Button/Yellow-Button";

import crossIcon from "../../../assets/cross.png";
import StripeError from "../../../models/StripeError";
import { useHistory } from "react-router";

interface CheckoutFormProps {
  stripe: any;
  elements: any;
  shopStore: ShopStore;
  goToOrderdone: Function;
}

interface CheckoutFormState {
  error: StripeError | null;
  isLoading: boolean;
}

class CheckoutForm extends React.Component<
  CheckoutFormProps,
  CheckoutFormState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      isLoading: false,
    };
  }

  handleSubmit = async (event) => {
    this.setState({ isLoading: true });

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const { stripe, elements }: any = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(
      this.props.shopStore.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      }
    );

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      this.setState({ error: result.error });
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.

        this.props.goToOrderdone();
      }
    }
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <div>
        <div
          className="payment-close"
          onClick={() => {
            this.props.shopStore.hidePaymentDialog();
          }}
        >
          <img src={crossIcon} />
        </div>
        <form onSubmit={this.handleSubmit}>
          <CardSection
            error={this.state.error}
            onChange={() => {
              this.setState({ error: null });
            }}
          />

          <YellowButton
            loading={this.state.isLoading}
            onClick={() => {}}
            label="Zahlen"
          />
        </form>
      </div>
    );
  }
}

interface InjectedCheckoutFormProps {
  shopStore?: ShopStore;
}

const InjectedCheckoutForm = ({ shopStore }: InjectedCheckoutFormProps) => {
  const history = useHistory();

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          shopStore={shopStore!}
          goToOrderdone={() => {
            shopStore!.clearShoppingCard();
            history.push("/orderdone");
          }}
        />
      )}
    </ElementsConsumer>
  );
};

export default inject("shopStore")(InjectedCheckoutForm);
