import React, { Component, useEffect, useState } from 'react';
import './ProductWinPage.css';
import Header from '../../../components/header/Header';
import Loading from '../../../components/loading/Loading';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import ShopCart from '../../../components/shop-cart/ShopCart';
import { TitleHeaderGreen } from '../../../components/title-header-green/TitleHeaderGreen';
import ShopStore from '../../../mobx/ShopStore';
import UserStore from '../../../mobx/UserStore';
import InfoModal from '../../../components/InfoModal/InfoModal';

interface ProductWinProps {
  userStore?: UserStore;
  shopStore?: ShopStore;
}

const ProductWinPage = ({ userStore, shopStore }: ProductWinProps) => {
  useEffect(() => {
    shopStore!.loadProductGroups(true);
    userStore!.getCurrentUser();
    return () => {};
  }, []);

  const history = useHistory();
  return (
    <div className='productWinContainer'>
      <TitleHeaderGreen
        title='Preise'
        subtitle='Hier kannst du deine Preise im Mohren Lädele abholen!'
      />
      {shopStore!.instantPrices.length > 0 ? (
        userStore!.currentUser.pickups.length > 0 ? (
          userStore!.currentUser.pickups.map((item: any, key) => {
            var pickup = item;
            if (!isNaN(pickup.product)) {
              const product = shopStore!.getProductById(item.product, true);
              pickup.product = product;
            }
            return (
              <div key={key}>
                <ShopCart pickup={pickup} displayOnly />
              </div>
            );
          })
        ) : (
          <div className='productwin-no-prices'>
            Du hast noch keine Preise gewonnen.
          </div>
        )
      ) : (
        <div></div>
      )}
      <Loading />
      <InfoModal
        title='Achtung!'
        text='Sofortpreise können noch bis zum 30. August in unserem Mohrenbräu Lädele abgeholt werden!'
        buttonText='Okay'
      />
      <Header backEnabled backUrl='/profil'></Header>
    </div>
  );
};

export default inject('userStore', 'shopStore')(observer(ProductWinPage));
