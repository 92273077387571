import React, { Component, useEffect, useState } from 'react';
import './App.css';

//Global accessable components
// State
import { Provider as MobxProvider } from 'mobx-react';

//stores
import EventStore from './mobx/EventStore';
import NewsStore from './mobx/NewsStore';
import StoryStore from './mobx/StoryStore';
import ShopStore from './mobx/ShopStore';
import UserStore from './mobx/UserStore';
import CustomTrikotStore from './mobx/CustomTrikotStore';
import MohrenRouter from './router/MohrenRouter';
import QuestionStore from './mobx/QuestionStore';

//Stripe

import Maintenance from './components/Maintenance/Maintenance';
import StripeLoader from './components/StripeLoader/StripeLoader';
import InfoModal from './components/InfoModal/InfoModal';

const eventStore = new EventStore();
const newsStore = new NewsStore();
const storyStore = new StoryStore();
const shopStore = new ShopStore();
const customTrikotStore = new CustomTrikotStore();
const userStore = new UserStore();
const questionStore = new QuestionStore();

questionStore.addUserStore(userStore);
storyStore.addUserStore(userStore);

const App = () => {
  const [hasStripeCookie, setHasStripeCookie] = useState(false);

  const handleCookieChange = (event) => {
    if (!hasStripeCookie) {
      setHasStripeCookie(true);
    }
  };

  useEffect(() => {
    const cookiebot = (window as any).Cookiebot;
    const stamp = cookiebot?.consent?.stamp;
    if (stamp && stamp !== 0 && stamp !== '0' && stamp !== '') {
      setHasStripeCookie(true);
    } else {
      window.addEventListener('CookiebotOnAccept', handleCookieChange);
      window.addEventListener('CookiebotOnDecline', handleCookieChange);
    }

    return () => {
      window.removeEventListener('CookiebotOnAccept', handleCookieChange);
      window.removeEventListener('CookiebotOnDecline', handleCookieChange);
    };
  }, []);

  const renderRouting = () => {
    if (hasStripeCookie) {
      return (
        <StripeLoader>
          <MobxProvider
            eventStore={eventStore}
            newsStore={newsStore}
            customTrikotStore={customTrikotStore}
            storyStore={storyStore}
            shopStore={shopStore}
            userStore={userStore}
            questionStore={questionStore}
          >
            <MohrenRouter />
          </MobxProvider>
        </StripeLoader>
      );
    }

    return <></>;
  };

  return (
    <>
      {process.env.REACT_APP_MAINTENANCE === 'true' ? (
        <Maintenance />
      ) : (
        <div className='app-main-container'>
          {renderRouting()}
        </div>
      )}
    </>
  );
};

export default App;
