import React, { Component, useState } from 'react';
import './EmailRegistration.css';
import TextInput from '../../components/TextInput/TextInput';
import headerlogo from '../../assets/mohren-logo.png';
import Loading from '../../components/loading/Loading';
import Header from '../../components/header/Header';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import './EmailLogin.css';
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import { inject } from 'mobx-react';
import UserStore from '../../mobx/UserStore';
import { useHistory } from 'react-router-dom';
import Container from '../../components/Container/Container';
import WhiteButton from '../../components/White-Button/White-Button';

interface EmailLoginProps {
  userStore?: UserStore;
}

const EmailLogin = ({ userStore }: EmailLoginProps) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();

  return (
    <Container>
      <div className='register-container'>
        <div className='register-content'>
          {loading ? (
            <Loading />
          ) : (
            <div className='register-container-form'>
              <TitleHeaderGreen title='Anmelden' subtitle='' />
              <TextInput
                placeholder='E-Mail'
                type='email'
                value={email}
                onChange={(event: any) => {
                  setEmail(event.target.value);
                }}
              ></TextInput>
              <TextInput
                type='password'
                placeholder='Passwort'
                value={password}
                onChange={(event: any) => {
                  setPassword(event.target.value);
                }}
                onEnter={() => {
                  //  this.props.login(email, password);
                }}
              ></TextInput>
              <div className='register-agb-center'>
                <div
                  className='link'
                  onClick={() => {
                    history.push('/forgotpw');
                  }}
                >
                  Passwort vergessen?
                </div>
              </div>
              <div style={{ marginTop: '10px', width: '100%' }}>
                <WhiteButton
                  label='Login'
                  action={async () => {
                    if (email === '') {
                      Swal.fire({
                        text: 'Bitte gib deine Email-Adresse ein!',
                        type: 'error',
                        confirmButtonColor: '#ffd42e',
                        confirmButtonText: 'Ok',
                      });
                      return;
                    }
                    if (password === '') {
                      Swal.fire({
                        text: 'Bitte gib ein Passwort ein!',
                        type: 'error',
                        confirmButtonColor: '#ffd42e',
                        confirmButtonText: 'Ok',
                      });
                      return;
                    }
                    //  this.checkCaptcha();
                    //   this.props.login(email, password);
                    setLoading(true);
                    if (await userStore!.login(email, password)) {
                      if (
                        userStore!.currentUser &&
                        userStore!.currentUser.username
                      ) {
                        history.push('/home');
                      } else {
                        history.push('/username');
                      }
                    }
                    setLoading(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <Header backEnabled />
      </div>
    </Container>
  );
};

export default inject('userStore')(EmailLogin);
