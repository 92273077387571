import React, { Component } from "react";
import "./Loading.css";
import Modal from "react-modal";
import loading_green from "../../assets/mohren_loading_transp_new.gif";
import loading_white from "../../assets/mohren-loader-weiss.gif";
import app_icon from "../../assets/app-icon.svg";

import { inject, observer } from "mobx-react";

class Loading extends Component<
  {
    isVisible?: boolean;
    loadingComponent?: any;
    show?: boolean;
    isBGWhite?: boolean;
  },
  {}
> {
  private customStyles = {
    content: {
      backgroundColor: this.props.isBGWhite ? "white" : "rgba(0, 117, 64, 0.7)",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: 0,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "transparent",
    },
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isVisible || this.props.show}
        style={this.customStyles}
        contentLabel="Loading Modal"
      >
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
        >
          {this.props.loadingComponent && (
              <div> {this.props.loadingComponent}</div>
          )}

          <div className="loader">
            <img width={45} height={45} src={app_icon} className="loading_logo"/>
          </div>

        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    isVisible: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default Loading;
