import { observable, action } from "mobx";
import News from "../models/News";
import StrapiHelper from "../services/StrapiHelper.service";

export default class NewsStore {
  @observable
  news: News[] = [];

  @observable
  currentNews?: News;

  @action
  setCurrentNews(news?: News) {
    this.currentNews = news;
  }

  @action
  async loadNewsArticle(id: string) {
    let res = await StrapiHelper.loadNewsArticle(id);
    let event = res.data as News;
    this.currentNews = event;
  }

  @action
  async loadNews() {
    let res = await StrapiHelper.loadNews();
    let news = res.data as News[];
    this.news = news;
  }
}
