import React, { Component } from "react";
import "./TextInput.scss";
import Autocomplete from "react-google-autocomplete";

interface TextInputProps {
    placeholder: string;
    onChange?: any;
    value?: any;
    type?: string;
    autoComplete?: string;
    inValid?: boolean;
    onEnter?: any;
    disabled?: boolean;
    style?: any;
    isGoogleInput?: boolean;
    onGooglePlaceSelected?: (place: any) => void;
    numberMode?: boolean; // New prop to toggle number mode
}

interface TextInputState {
  opened: boolean;
}

class TextInput extends Component<TextInputProps, TextInputState> {
  private _input: any;


  constructor(props: any) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  onEnterPressed(event: any) {
    if (event.key == "Enter") {
      if (this.props.onEnter) {
        this.props.onEnter();
      }
    }
  }

  handleAutoFill(e) {}

  renderGoogleInput = () => {
    if (this.props.disabled) {
      return (
        <input
          ref={(ref) => (this._input = ref)}
          className="textinput-gray disabled"
          type={this.props.type ? this.props.type : "text"}
          value={this.props.value}
          onFocus={() => {
            this.setState({ opened: true });
          }}
          onBlur={() => {
            this.setState({ opened: false });
          }}
          onChange={(event) => this.props.onChange(event)}
          disabled={this.props.disabled}
          spellCheck={false}
        />
      );
    }
    return (
      //@ts-ignore
      <Autocomplete
        ref={this._input}
        className={"textinput-gray " + (this.props.disabled && "disabled")}
        types={["address"]}
        fields={[
          "address_components",
          "geometry.location",
          "place_id",
          "formatted_address",
        ]}
        componentRestrictions={{ country: "at" }}
        value={this.props.value}
        placeholder={""}
        onChange={(event) => this.props.onChange(event)}
        onPlaceSelected={(place: any) =>
          this.props.onGooglePlaceSelected &&
          this.props.onGooglePlaceSelected(place)
        }
        onFocus={() => {
          this.setState({ opened: true });
        }}
        onBlur={() => {
          this.setState({ opened: false });
        }}
      />
    );
  };

    render() {
        const inputType = this.props.numberMode ? "number" : (this.props.type || "text");

        if (this.props.disabled) {
            return (
                <div className="field-container" style={this.props.style}>
                    <div className="field-group field-group-active">
                        <label
                            className={
                                this.state.opened || this.props.value.length > 0 || typeof this.props.value === 'number'
                                    ? "field-active"
                                    : "field-inactive"
                            }
                        >
                            <div className="placeholdeText">{this.props.placeholder}</div>
                        </label>
                        {this.props.isGoogleInput ? (
                            this.renderGoogleInput()
                        ) : (
                            <input
                                ref={(ref) => (this._input = ref)}
                                className="textinput-gray disabled"
                                type={inputType}
                                value={this.props.value}
                                onFocus={() => {
                                    this.setState({ opened: true });
                                }}
                                onBlur={() => {
                                    this.setState({ opened: false });
                                }}
                                onChange={(event) => this.props.onChange(event)}
                                disabled={this.props.disabled}
                                spellCheck={false}
                            />
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="field-container"
                    style={this.props.style}
                    onKeyPress={(event) => {
                        this.onEnterPressed(event);
                    }}
                    onClick={() => this.setState({ opened: true })}
                    onBlur={() => this.setState({ opened: false })}
                >
                    <div
                        className={
                            this.state.opened ||
                            (this.props.value && this.props.value.length > 0) || typeof this.props.value === 'number'
                                ? "field-group field-group-active"
                                : "field-group"
                        }
                    >
                        <label
                            className={
                                this.state.opened || this.props.value.length > 0 || typeof this.props.value === 'number'
                                    ? "field-active"
                                    : "field-inactive"
                            }
                        >
                            <div
                                className={
                                    this.state.opened || !this.props.inValid
                                        ? "placeholdeText"
                                        : "placeholdeText-error"
                                }
                            >
                                {this.props.placeholder}
                            </div>
                        </label>
                        {this.props.isGoogleInput ? (
                            this.renderGoogleInput()
                        ) : (
                            <input
                                ref={(ref) => (this._input = ref)}
                                className={this.props.inValid ? "textinput-error" : "textinput-gray"}
                                type={this.props.numberMode ? "number" : (this.props.type || "text")}
                                min={this.props.numberMode ? "0" : undefined} // Apply min only if numberMode is true
                                inputMode={this.props.numberMode ? "numeric" : undefined} // Set inputMode to numeric if numberMode is true
                                pattern={this.props.numberMode ? "[0-9]*" : undefined} // Use the pattern if numberMode is true
                                value={this.props.value}
                                onFocus={() => {
                                    this.setState({opened: true});
                                }}
                                onBlur={() => {
                                    this.setState({opened: false});
                                }}
                                onChange={(event) => this.props.onChange(event)}
                                onAnimationStart={this.handleAutoFill}
                                spellCheck={false}
                                disabled={this.props.disabled}
                            />
                        )}
                    </div>
                </div>
            );
        }
    }

}

export default TextInput;
