import React, { Component } from "react";
import Order from "../../../../models/Order";
import "./OrderHistoryCard.css";
import Moment from "react-moment";
import OrderPart from "../../../../models/OrderPart";

const ORDER_STATUS_TRANSLATION = {
  NichtBezahlt: "Nicht bezahlt",
  Bezahlt: "Bezahlt",
  Versendet: "Versendet",
  Vorbereitung: "In Vorbereitung",
  Zugestellt: "Zugestellt",
  Abholbereit: "Abholbereit",
};

export default class OrderHistoryCard extends Component<{ order: any }, {}> {
  _renderOrderParts() {
    return this.props.order.OrderPart.map((item, key) => {
      if (item.image && item.image.length > 0) {
        const url = item.image[0].url;
        return (
          <div className="order-history-part">
            <div className="order-history-part-image">
              <img src={url} />
            </div>
            <div className="order-history-part-product" style={{}}>
              <div className="order-history-text">{item.Productname}</div>
              <div className="order-history-text">Menge: {item.Amount}</div>
            </div>
          </div>
        );
      }
      return <div></div>;
    });
  }

  render() {
    let order = this.props.order;
    return (
      <div className="order-history-card">
        <div className="order-history-card-header">
          <div className="order-history-left">
            <div className="order-history-detials">
              <div className="order-history-title">Bestellung</div>
              <div className="order-history-number-text">
                {order.ordernumber}
              </div>
              <Moment
                className="order-history-number-text"
                format="HH:mm DD.MM.YYYY"
              >
                {order.orderdate}
              </Moment>
            </div>
            <div className="order-history-status green">
              {ORDER_STATUS_TRANSLATION[order.status]}
            </div>
          </div>
          <div className="order-history-right">
            <div className="order-history-price">
              {order.totalPrice ? (""+order.totalPrice.toFixed(2)).replace(".",",") : 0}€
            </div>
          </div>
        </div>
        <div className="order-history-seperator"></div>
        <div>{this._renderOrderParts()}</div>
      </div>
    );
  }
}
