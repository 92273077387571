import React from "react";
import "./FAQ.css";
import Header from "../../components/header/Header";
import { TitleHeaderGreen } from "../../components/title-header-green/TitleHeaderGreen";
import TextSlideDown from "../../components/TextSlideDown/TextSlideDown";

const faq = [
  {
    category: "Mitgliedskonto",
    questions: [
      {
        question:
          "Wo kann ich meine persönlichen Daten einsehen/aktualisieren?",
        answere:
          "Deine persönlichen Daten kannst du im Menüpunkt „Persönliche Daten“ einsehen und bearbeiten.",
      },
      {
        question: "Wie werden meine persönlichen Daten behandelt?",
        answere:
          "Deine Daten werden von uns vertraulich behandelt und nicht an Dritte weitergegeben. Alle Infos findest du in den Datenschutzbestimmungen.",
      },
      {
        question: "Wie kann ich mein Profil löschen? ",
        answere:
          "Unter dem Menüpunkt „Mein Profil“ kannst du dein Mitgliedskonto löschen. Mit der Löschung deines Kontos verfallen deine erstellten Profile unwiderruflich.",
      },
      {
        question: "Ist eine Kündigung meiner Teilnahme durch Mohren möglich?",
        answere:
          "Ja, Mohren kann, bei Vorliegen eines triftigen Grundes, z. B. bei Missbrauch oder Täuschungsversuch, die Teilnahme fristlos und mit sofortiger Wirkung kündigen.",
      },
    ],
  },
];

const FAQ = () => {
  return (
    <div className="faq">
      <TitleHeaderGreen
        title="FAQs"
        subtitle="Hier finden Sie Antworten für häufig gestellte Fragen."
      />
      <div className="faq-title">
        Hier die meistgestellten Fragen zur "Kronkorken"-Aktion
      </div>
      <div className="faq-subtitle">
        Wir haben deine Antworten! Auf der folgenden Seite haben wir versucht
        immer wiederkehrende Fragen für dich aufzubereiten. Zu verschiedenen
        Themen kannst du kurze und schnelle Infos finden.
        <br />
        <br />
        Für alle anderen Fragen zum Gewinnspiel kontaktiere uns unter{" "}
        {/* <a href="mailto:teamspezial@mohrenbrauerei.at" className="link"> */}
          gewinnen@mohrenbrauerei.at
        {/* </a>{" "} */}- wir werden uns umgehend mit dir in Verbindung setzen.
      </div>

      {faq.map((item: any, index) => {
        return (
          <div key={index} style={{ padding: 20 }}>
            <div className="faq-question-title">{item.category}</div>
            {item.questions.map((item, index) => {
              return (
                <div className="textslide-seperator" key={index}>
                  <TextSlideDown item={item} />
                </div>
              );
            })}
            <div className="textslide-seperator"></div>
          </div>
        );
      })}
      <Header backEnabled />
    </div>
  );
};

export default FAQ;
