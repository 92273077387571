export function getAddressFromPlace(place: any) {
    let street = getComponent(place, "route")
    const streetNr = getComponent(place, "street_number")
    let city = getComponent(place, "locality")
    const zip = getComponent(place, "postal_code")
    const country = getComponent(place, "country")
    const countryCode =  getComponent(place, "country", true)

    // If the user only enters a city in the google search field, the response
    // contains the city as 'route' -> will be parsed as street. Check the values
    // and swap if needed.
    if (street && !city) {
        city = street
        street = ''
    }

    return {
        street,
        streetNr,
        city,
        zip,
        country,
        countryCode
    }
}

function getComponent(place: any, type: string, getShortName?: boolean) {
    const addressComponents = place["address_components"]
    if (addressComponents) {
        const component = addressComponents.find((component: any) => component["types"].includes(type))
        if (!component) {
            return null
        } 
        return component[getShortName ? "short_name" : "long_name"]
    }
}