import React, { useEffect, useState } from 'react';
import './Header.css';
import { isMobile } from 'react-device-detect';

import logo from '../../assets/logo_new_small.svg';
import back from '../../assets/back.png';
import qrCodeIcon from '../../assets/qr-code-icon.svg';
import shoppingCartIcon from '../../assets/shoppingcardicon.png';
import { inject, observer } from 'mobx-react';
import ShopStore from '../../mobx/ShopStore';
import { useHistory } from 'react-router';
import EventStore from '../../mobx/EventStore';

interface HeaderProps {
  backEnabled?: boolean;
  cameraEnabled?: boolean;
  backUrl?: any;
  shoppingCartEnabled?: boolean;
  backWithUrl?: any;
  shopStore?: ShopStore; // always injected my mobx
  red?: boolean;
  customBack?: any;
  eventStore?: EventStore;
}

const Header = ({
  backEnabled,
  cameraEnabled = false,
  red,
  shopStore,
  shoppingCartEnabled,
  eventStore,
}: HeaderProps) => {
  const [numberOfCartItems, setNumberOfCartItems] = useState(0);
  const history = useHistory();

  useEffect(() => {
    shopStore!.loadShopCardItems();

    const numberOfCartItems = shopStore!.getNumberOfCartItems();

    setNumberOfCartItems(numberOfCartItems);
  }, [shopStore]);

  const goBack = () => {
    history.goBack();
  };

  const isUserAuthentied = () => {
    if (
      window.localStorage.getItem('user') &&
      window.localStorage.getItem('mohren_token')
    ) {
      return true;
    }
    return false;
  };

  const renderShoppingCart = () => {
    if (shoppingCartEnabled) {
      return (
        <div
          className='header-right-container'
          onClick={() => {
            history.push('/shoppingcart');
          }}
        >
          {numberOfCartItems > 0 && (
            <div className='shop-cart-counter'>
              <div
                className='shop-cart-counter-text'
                style={numberOfCartItems > 9 ? { right: 0 } : {}}
              >
                {numberOfCartItems > 9 ? '9+' : numberOfCartItems}
              </div>
            </div>
          )}
          <img src={shoppingCartIcon} />
        </div>
      );
    } else return <div style={{ width: 30, height: 30 }} />;
  };

  const renderInnerHeaderUnAuthorized = () => {
    return (
      <div className='main-header-container'>
        <div className='header-container-unauthorized'>
          {backEnabled && (
            <div
              onClick={() => {
                goBack();
              }}
              style={{
                width: 30,
                cursor: 'pointer',
              }}
            >
              <img src={back} style={{ height: 21 }} />
            </div>
          )}
          <div className='header-logo'>
            <img
              src={logo}
              onClick={() => {
                history.replace('/home');
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderInnerHeader = () => {
    return (
      <div className='main-header-container'>
        <div className='header-container'>
          <div className='header-links'>
            {!backEnabled && isMobile && cameraEnabled && (
              <div>
                <a href='https://web-ar.mohren.app/' target='_blank'>
                  <img src={qrCodeIcon} style={{ height: 30 }} />
                </a>
              </div>
            )}
            {backEnabled && (
              <div
                onClick={() => {
                  goBack();
                }}
                style={{
                  width: 30,
                  height: '100%',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img src={back} style={{ height: 21 }} />
              </div>
            )}
          </div>
          <div className='header-logo'>
            <img src={logo} style={{ height: 41 }} />
          </div>
          <div className='header-cart'>{renderShoppingCart()}</div>
        </div>

        <div className='header-container-nav'>
          <div className='header-logo-desktop'>
            <img
              src={logo}
              onClick={() => {
                history.replace('/home');
              }}
            />
          </div>
          <div className='header-links'>
            <div
              className='nav-link'
              onClick={() => {
                if (eventStore) {
                  eventStore.setBrauSilvesterEvent();
                  history.push('/eventdetails');
                }
              }}
            >
              Brausilvester
            </div>
            <div
              className='nav-link'
              onClick={() => {
                history.push('/events');
              }}
            >
              Events
            </div>
            <div
              className='nav-link'
              onClick={() => {
                history.push('/store');
              }}
            >
              s'Lädele
            </div>
            <div
              className='nav-link'
              onClick={() => {
                history.push('/profil');
              }}
            >
              Profil
            </div>
          </div>
          <div className='header-cart'>{renderShoppingCart()}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={red ? 'header-red' : 'header'}>
      {isUserAuthentied() && renderInnerHeader()}
      {!isUserAuthentied() && renderInnerHeaderUnAuthorized()}
    </div>
  );
};

export default inject('shopStore', 'eventStore')(observer(Header));
