import React, { useEffect, useRef, useState } from 'react';
import './ProductDetailCustomBox.css';
import '../../../../components/Yellow-Button/Yellow-Button.css';
import { inject, observer } from 'mobx-react';
import ShopStore from '../../../../mobx/ShopStore';
import YellowButton from '../../../../components/Yellow-Button/Yellow-Button';
import CustomTrikotStore from '../../../../mobx/CustomTrikotStore';
import { ImagePicker } from './ImagePicker';
import 'rodal/lib/rodal.css';
import 'react-image-crop/dist/ReactCrop.css';
import ImageTaker from '../ImageTaker/ImageTaker';
import CustomTshirtIcon from '../../../../assets/custom_tshirt_preview_old.png';
import loading_white from '../../../../assets/mohren-loader-weiss.gif';
import { TitleHeaderGreen } from '../../../../components/title-header-green/TitleHeaderGreen';
import { useHistory, useParams } from 'react-router';
import jimp from 'jimp';
import back from '../../../../assets/back.png';
import CropRodal from '../CropRodal/CropRodal';
import Loading from '../../../../components/loading/Loading';
import Container from '../../../../components/Container/Container';
interface ProductDetailCustomProps {
  shopStore: ShopStore;
  customTrikotStore: CustomTrikotStore;
  match: any;
  home: Function;
  navigateTo: Function;
  user: any;
  router: any;
  back: any;
}

const ProductDetailCustomBox = ({
  customTrikotStore,
  shopStore,
}: ProductDetailCustomProps) => {
  const history = useHistory();
  const ref = useRef(null as any);
  //@ts-ignore
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loadForShoppingCard, setLoadForShoppingCard] = useState(false);
  const [isImageTakerVisible, setIsImageTakerVisible] = useState(false);
  const [name, setName] = useState('');
  const [galleryImage, setGalleryImage]: any = useState('');
  const [imagePickerHeight, setimagePickerHeight] = useState('auto');

  useEffect(() => {
    setimagePickerHeight(ref.current.clientWidth);
    if (!shopStore!.currentOrderPart) {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    const split = window.location.pathname.split('/');
    if (split && split[2]) {
      shopStore!.loadCurrentShopItem(parseInt(split[2]));
    }
  }, [id]);

  useEffect(() => {
    if (shopStore!.currentShopItem) {
      setName(shopStore!.currentShopItem.name);
    }
  }, [shopStore!.currentShopItem]);

  return (
    <Container>
      <div className='custom-product-detail-fullheight'>
        <div
          className='back-container'
          onClick={() => {
            history.goBack();
          }}
        >
          <img className='back' src={back} />
        </div>
        {loadForShoppingCard && (
          <Loading
            isVisible={loadForShoppingCard}
            loadingComponent={
              <TitleHeaderGreen
                title='Datenverarbeitung'
                subtitle='Wir verarbeiten gerade deine Daten. Bitte habe etwas Geduld.'
              />
            }
          />
        )}
        <div className='product-custom-container'>
          <TitleHeaderGreen
            title={name}
            subtitle='Gestalte dein persönliches Trikot.'
          />
          <div className='product-custom-image-container'>
            <img
              ref={ref}
              src={
                shopStore!.currentShopItem &&
                shopStore!.currentShopItem.specialImageBackground
                  ? shopStore!.currentShopItem.specialImageBackground.url
                  : CustomTshirtIcon
              }
              style={{
                height: imagePickerHeight,
              }}
            />
            {customTrikotStore.cutOutImage != '' && !isLoading && (
              <div className='user-image'>
                <img src={customTrikotStore.cutOutImage} />
              </div>
            )}

            {customTrikotStore.cutOutImage == '' && !isLoading && (
              <div className='user-image'></div>
            )}
            {isLoading && <img src={loading_white} className='user-image' />}
          </div>
          <div className='bottom-container'>
            {customTrikotStore.cutOutImage != '' && (
              <YellowButton
                loading={isLoading}
                label='Foto ändern'
                style={{ marginBottom: 15 }}
                onClick={() => {
                  customTrikotStore!.clearCutOutImage();
                }}
              />
            )}
            {!customTrikotStore.cutOutImage && !isLoading && (
              <>
                <YellowButton
                  loading={isLoading}
                  style={{ marginBottom: 15 }}
                  label={'Foto aufnehmen'}
                  onClick={() => {
                    setIsImageTakerVisible(true);
                  }}
                />
                <ImagePicker
                  extensions={['jpg', 'jpeg', 'png']}
                  dims={{
                    minWidth: 100,
                    maxWidth: 10000,
                    minHeight: 100,
                    maxHeight: 10000,
                  }}
                  maxSize={10000}
                  onChange={async (base64String) => {
                    setIsLoading(true);

                    const buff = Buffer.from(
                      base64String.split(',')[1],
                      'base64'
                    );
                    const resizeImage = async () => {
                      const image = await jimp.read(buff);
                      image
                        .resize(
                          1280,
                          1280 * (image.getHeight() / image.getWidth())
                        )
                        .getBase64(jimp.MIME_PNG, async function (err, src) {
                          setGalleryImage(src);
                        });
                    };
                    resizeImage();
                  }}
                  onError={(errMsg) => {
                    console.log(errMsg);
                  }}
                >
                  <YellowButton
                    loading={isLoading}
                    label='Aus Galerie auswählen'
                  />
                </ImagePicker>
              </>
            )}
            {customTrikotStore.cutOutImage && !isLoading && (
              <YellowButton
                loading={isLoading}
                style={{ marginBottom: 15 }}
                label={'In den Warenkorb'}
                onClick={async () => {
                  setLoadForShoppingCard(true);
                  const resp = await shopStore.addCustomImageToOrderAndStore(
                    shopStore.currentOrderPart as any,
                    customTrikotStore.image
                  );
                  setLoadForShoppingCard(false);
                  history.push('/shoppingcart');
                }}
              />
            )}
          </div>
          {!customTrikotStore.cutOutImage && (
            <ImageTaker
              visible={isImageTakerVisible}
              onPhotoTaken={async (image) => {
                try {
                  setIsLoading(true);
                  await customTrikotStore!.setCutOutImage(image);
                  setIsLoading(false);
                } catch (err) {
                  console.log(err);
                }
              }}
              closeRodal={() => {
                setIsImageTakerVisible(false);
              }}
            />
          )}
          {galleryImage != '' && (
            <CropRodal
              image={galleryImage}
              onPhotoTaken={async (image) => {
                try {
                  setIsLoading(true);
                  await customTrikotStore!.setCutOutImage(image);
                  setIsLoading(false);
                } catch (err) {
                  console.log(err);
                }
              }}
              closeRodal={() => {
                setGalleryImage('');
              }}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default inject(
  'shopStore',
  'customTrikotStore'
)(observer(ProductDetailCustomBox));
