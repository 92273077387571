import Order from "../models/Order";
import OrderPart from "../models/OrderPart";
import { v4 as uuidv4 } from "uuid";

import Axios from "./Axios";

export default class StrapiHelper {
  public static getCurrentUser() {
    return Axios.get("/users/me");
  }

  public static deleteUser() {
    return Axios.post("/users/delete-profile");
  }

  public static emailRegister(email: string, password: string) {
    return Axios.post("/auth/local/register", {
      email: email,
      password: password,
    });
  }

  public static loginWithFB(token: string) {
    return Axios.get("/auth/facebook/callback" + token);
  }

  public static loginWithApple(token: string) {
    console.log(token);
    return Axios.get("/auth/apple/callback" + token);
  }

  public static emailLogin(email: string, password: string) {
    return Axios.post("/auth/local", {
      identifier: email,
      password: password,
    });
  }

  public static resetPassword(
    code: string,
    password: string,
    repeatedPassword: string
  ) {
    return Axios.post("/auth/reset-password", {
      code, // code contained in the reset link of step 3.
      password,
      passwordConfirmation: repeatedPassword,
    });
  }
  public static forgotMyPassword(email: string) {
    return Axios.post("/auth/forgot-password", {
      email: email,
    });
  }

  public static getLeaderBoardByFilter(filter: string) {
    return Axios.get("/users/leaderboard/" + filter);
  }

  public static redeemCode(code: string) {
    return Axios.post("/codes/redeem/" + code);
  }

  public static buyTicket(mainPriceId: number, ticketAmount: number) {
    return Axios.post("/tickets/buy", {
      mainPriceId: mainPriceId,
      ticketAmount: ticketAmount,
    });
  }

  public static updateUser(user: any) {
    return Axios.put("/users/" + user.id, user);
  }

  public static loadEvents() {
    return Axios.get("/events?_sort=startDateTime:ASC");
  }

  public static loadEvent(id: string) {
    return Axios.get("/events/" + id);
  }

  public static loadFeaturedEvent() {
    return Axios.get("/events?Featured=true&_limit=1");
  }

  public static loadNews() {
    return Axios.get("/posts?_sort=updated_at:DESC");
  }

  public static loadNewsArticle(id: string) {
    return Axios.get("/posts/" + id);
  }

  public static answereQuestion(answere: number, questionId: any) {
    return Axios.post("/quizzes/current/answer/", {
      userAnswer: answere,
      questionId: questionId,
    });
  }

  public static loadCurrentQuestion() {
    return Axios.get("/quizzes/current");
  }

  public static loadStories() {
    return Axios.get("/stories?_sort=updated_at:DESC");
  }

  public static loadProductGroups(isInstantOnly?: boolean) {
    if (isInstantOnly) {
      return Axios.get("/products?_sort=created_at:ASC");
    }
    return Axios.get("/products?_sort=created_at:ASC&instantPriceOnly=false");
  }

  public static loadMainPrices() {
    return Axios.get("/mainprices");
  }

  public static getOneProduct(id: any) {
    return Axios.get(`/products/${id}`);
  }

  public static loadMySpezialWinner() {
    return Axios.get("/data?type=MYSPEZIAL111&_limit=1");
  }

  public static createOrder(order: Order) {
    let orderObj = order as Order;
    return Axios.post("/orders", orderObj);
  }

  public static getShipmentCost(orderParts: OrderPart[]) {
    return Axios.post("/shipping", { orderparts: orderParts });
  }

  public static loadOrders() {
    return Axios.get("/orders");
  }

  public static checkAvailablity(orderParts: OrderPart[]) {
    return Axios.post("/orders/available", orderParts);
  }

  public static createIntend(order: Order) {
    return Axios.post("/orders/create-payment-intent", order);
  }

  public static removeBackground(image: string, maxRes?: boolean) {
    return Axios.post("/orders/removebg", { image, maxRes });
  }

  public static getCurrentGames() {
    return Axios.get("/games");
  }

  public static getPastGames() {
    return Axios.get("/games/past");
  }

  public static getGameById(gameID: string) {
    return Axios.get(`/games/${gameID}`);
  }

  public static createTip(tip: any) {
    return Axios.post(`/tips`, { tip });
  }

  public static getPickups() {
    return Axios.get("/pickups");
  }

  public static getWeeklyPrizes() {
    return Axios.get("/weekly-prices");
  }

  public static getAllTickets() {
    return Axios.get("/coupons");
  }

  public static uploadImage(image: string) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("mohren_token"),
      },
    };
    return new Promise(async (resolve, reject) => {
      const response = await fetch(image);
      const blob = await response.blob();

      const fd = new FormData();
      fd.append("files", blob, uuidv4() + ".png");
      return Axios.post("/upload", fd, config)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
