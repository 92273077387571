import React, { useEffect, useState } from "react";
import "./PasswordRecovery.css";
import Container from "../../components/Container/Container";
import Header from "../../components/header/Header";
import TextInput from "../../components/TextInput/TextInput";
import { TitleHeaderGreen } from "../../components/title-header-green/TitleHeaderGreen";
import YellowButton from "../../components/Yellow-Button/Yellow-Button";
import Loading from "../../components/loading/Loading";
import Swal from "sweetalert2";
import { inject } from "mobx-react";
import UserStore from "../../mobx/UserStore";
import { useHistory } from "react-router";

interface PasswordRecovery {
  userStore?: UserStore;
}

const PasswordRecovery = ({ userStore }: PasswordRecovery) => {
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      window.location &&
      window.location.search &&
      window.location.search.indexOf("code")
    ) {
      const codeFromUrl = window.location.search.replace("?code=", "");

      if (!codeFromUrl) {
        console.log("nocode");
      } else {
        setCode(codeFromUrl);
      }
    }
  }, []);

  const resetPassword = async () => {
    setLoading(true);
    if (code && password != "" && repeatedPassword != "") {
      if (password != repeatedPassword) {
        Swal.fire({
          text: "Die Passwörter sind nicht ident.",
          type: "error",
          confirmButtonColor: "#ffd42e",
          confirmButtonText: "Ok",
        });
        setLoading(false);
        return;
      }
      if (await userStore!.resetPassword(code, password, repeatedPassword)) {
        if (userStore!.currentUser && userStore!.currentUser.username) {
          history.push("/home");
        } else {
          history.push("/username");
        }
      } else {
        Swal.fire({
          text: "Der Code zum Zurücksetzten des Passworts ist nicht mehr gültig.",
          type: "error",
          confirmButtonColor: "#ffd42e",
          confirmButtonText: "Ok",
        });
      }
      setLoading(false);
    } else {
      Swal.fire({
        text: "Bitte fülle beide Paswortfelder ein",
        type: "error",
        confirmButtonColor: "#ffd42e",
        confirmButtonText: "Ok",
      });
      setLoading(false);

      return;
    }
  };

  if (loading) {
    return <Loading isVisible={loading} />;
  }

  return (
    <Container>
      <div className="password-recovery">
        <TitleHeaderGreen
          title="Passwort erstellen"
          subtitle="Setze dein Passwort zurück, in dem du ein neues Passwort zwei Mal eingibst."
        />
        <div className="input-container">
          <TextInput
            placeholder="Passwort"
            type="password"
            value={password}
            onChange={(event: any) => {
              setPassword(event.target.value);
            }}
          ></TextInput>
          <TextInput
            placeholder="Passwort wiederholen"
            type="password"
            value={repeatedPassword}
            onChange={(event: any) => {
              setRepeatedPassword(event.target.value);
            }}
          ></TextInput>
          <YellowButton
            style={{ marginTop: 15 }}
            label="Passwort zurücksetzten"
            onClick={() => {
              resetPassword();
            }}
          />
        </div>
      </div>
      <Header backEnabled />
    </Container>
  );
};

export default inject("userStore")(PasswordRecovery);
